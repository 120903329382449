import React from 'react'
import { IonItem, IonThumbnail, IonGrid, IonRow, IonCol, IonIcon, IonText} from '@ionic/react';
import { Link } from 'react-router-dom';
import { ManowEventInfo } from '@manow/service/dist/event';
import moment from 'moment'
import { pin, calendar } from 'ionicons/icons'

interface Props {
  event: ManowEventInfo
}

const EventListItem: React.FC<Props> = ({
  event
}) => {
  let eventLink = '/events';
  const startDateTime = event && event.EventStartDate ? event.EventStartDate.toDate() : Date.now();
  
  const sliceName = (title?: string) => {
    if(title && title.length > 0) {
      return title!.length > 55 ? `${title!.slice(0,55)}...` : title!;
    }
    return title;
  }
  return (
    <Link to={`${eventLink}/${event.EventKey}`} style={{ textDecoration: 'none' }}>
      <IonItem style={{marginTop: 15}}>
        <IonGrid style={{ width: '100%', height: '100%'}}>
          <IonRow>
            <IonCol>
             <IonText style={{fontSize: '1.25rem', size: 'small', fontWeight: 600}}>{sliceName(event.EventName)}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon style={{color: '#87cad4'}} icon={calendar}/>
              <IonText style={{paddingLeft: 2, color: '#87cad4'}}>{moment(startDateTime).format('DD/MM/YYYY HH:mm')}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon icon={pin}/>
              <IonText style={{paddingLeft: 2, fontSize: '0.9rem'}}>{event.EventLocationName}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonThumbnail slot="start" style={{width: 176.785714285, height: 250}}>
          <img alt="thumbnail" src={event.EventThumbnailImage || event.EventCoverImage || require('../../../assets/logoManow.png')} />
        </IonThumbnail>
      </IonItem>
    </Link>
  )
}

export default EventListItem
