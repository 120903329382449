import React from 'react'
import EventList from './EventList'
import { GetAllEventList } from '@manow/service/dist/event'
import { useObservable } from 'rxjs-hooks'
import { IonSpinner } from '@ionic/react'
import moment from "moment";

interface Props {
  
}

const FeaturedEventList: React.FC<Props> = () => {
  const eventList = useObservable(() => GetAllEventList(10, ref => ref.where('Privacy', '==', 'Public').orderBy('EventEndDate', 'desc').where('EventEndDate', '>=', new Date(moment().format("YYYY/MM/DD 00:00")))))
  if (!eventList) {
    return <IonSpinner />
  }
  return (
    <EventList title="กิจกรรมแนะนำ" events={eventList}/>
  )
}

export default FeaturedEventList
