import React from 'react'
import { useFormContext } from 'react-hook-form'
import { IonItem, IonText } from '@ionic/react';
interface Props {
  name: string
}

const FormErrorOutlet: React.FC<Props> = ({
  name
}) => {
  const {
    errors
  } = useFormContext()
  
  const error = errors[name]
  if (!error) {
    return null
  }
  return (
    <IonItem lines="none">
      <IonText color="danger">
        <small>{error.message}</small>
      </IonText>
    </IonItem>
  )
}

export default FormErrorOutlet
