import React from 'react'
import InputWrapper from './InputWrapper'
import { FirebaseApp } from '@manow/service'
// import { Plugins, CameraResultType } from '@capacitor/core';
import uuidv4 from 'uuid/v4'
import _ from 'lodash'
import { IonAvatar } from '@ionic/react';

// const { Camera } = Plugins;
interface Props {
  label?: string,
  name: string,
  hideLabel?: boolean,
  className?: string,
  style?: CSSStyleDeclaration,
  fileName?: string
}
interface ImagePickerProps {
  onChange?: (value: string) => void,
  fileName?: string,
  value?: string
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  onChange=()=>{},
  fileName,
  value=require('../../assets/profile.png')
}) => {
  // const cameraInput = useRef<HTMLInputElement>(null)
  // const filePickerInput = useRef<HTMLInputElement>(null)
  // const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   // onChange()
  //   // 
  //   const file = _.get(e, 'target.files[0]', undefined)
  //   const storage = FirebaseApp.storage();
  //   // const metadata = {
  //   //   contentType: 'image/jpeg'
  //   // };
  //   const storageRef = await storage.ref();
  //   const imageName = fileName || `${uuidv4()}.jpeg`; // a unique name for the image
  //   const imgFile = storageRef.child(imageName);
  //   try {
  //     const image = await imgFile.put(file);
  //     const url = await image.ref.getDownloadURL();
  //     onChange(url);
  //   } catch (e) {
  //     // onError(e);
  //     console.log(e)
  //   }
  // }
  const handleClick = async () => {
    let file: Blob
    const imagePicker = document.createElement('input')
    imagePicker.setAttribute('type', 'file')
    imagePicker.click()
    file = await new Promise(resolve => imagePicker.addEventListener('change', (e) => resolve(_.get(e, 'target.files[0]'))))
    if (!file) {
      return
    }
    // Can be set to the src of an image now
    // imageElement.src = imageUrl;
    // cameraInput.current!.click()
    const storage = FirebaseApp.storage();
    // const metadata = {
    //   contentType: 'image/jpeg'
    // };
    const storageRef = await storage.ref();
    const imageName = fileName || `${uuidv4()}.jpeg`; // a unique name for the image
    const imgFile = storageRef.child(imageName);
    try {
      const image = await imgFile.put(file);
      const url = await image.ref.getDownloadURL();
      onChange(url);
    } catch (e) {
      // onError(e);
      console.log(e)
    }
  }
  return (
    <div style={{width: '100%'}}>
      <div onClick={handleClick}>
        <IonAvatar style={{width: '130px', height: '130px', margin: 'auto'}}>
          <img alt="preview" src={value} />
        </IonAvatar>
        {/* <IonImg src={value} style={{width: '130px', margin: 'auto'}}/> */}
      </div>
    </div>
  )
}

const ImageInput: React.FC<Props> = ({
  name,
  label,
  hideLabel=false,
  fileName
}) => {
  return (
    <React.Fragment>
      <InputWrapper name={name} label={label} valueGetter={v => v}>
        <ImagePicker fileName={fileName} />
      </InputWrapper>
    </React.Fragment>
  )
}

export default ImageInput
