import React from 'react';
import { RouteComponentProps } from 'react-router'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonText, IonButton, IonLabel } from '@ionic/react';
import { Link } from 'react-router-dom';
import { User} from '@manow/service';
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks';
import QRCode from 'qrcode.react';

interface MatchParams {
    id: string,
    orderid: string,
}

interface Props extends RouteComponentProps<MatchParams> {
}

const TicketPage: React.FC<Props> = ({
    match
}) => {
    const {
        id,
        orderid,
    } = match.params
    const {
        user
    } = useAuth();
    const userTicket = useObservable(() => User.GetUserTicketDetail(user!.uid, id));
    if (!userTicket) {
        return (<div>Loading</div>) // loading
    }
    const qrCodeTxt =`{ UserKey: '${userTicket.UserKey}', UserTicketKey: '${userTicket.UserTicketKey!}' }`;
    return (
        <IonCardContent>
            {/* <IonCard > */}
                {/* <IonImg style={{height: '300px'}} src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example"/> */}
            <QRCode value={qrCodeTxt}/>
            {/* </IonCard> */}
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Ticket#: {id}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonText color="dark">
                        <h3>Name: {userTicket.UserTicketFirstName} {userTicket.UserTicketLastName}</h3>
                    </IonText>
                    <IonLabel>
                        <h3>Ticket: {userTicket.UserTicketName}</h3>
                    </IonLabel>
                </IonCardContent>
            </IonCard>
            <Link to={`/orders/show/${orderid}`}>
                <IonButton expand="block">Back</IonButton>
            </Link>
        </IonCardContent>
    )
}

export default TicketPage;