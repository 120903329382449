import React, { useState } from 'react';
import MainLayout from '../../../layout/MainLayout'
import { IonContent, IonText, IonButton } from '@ionic/react';
import { useAuth } from '@manow/auth';
import { useHistory } from 'react-router';
import { useDeepCompareEffect } from 'react-use'

interface Props {
  
}

const CheckVerifyEmail:React.FC<Props> = () => {
  const { user, logout, sendVerificationEmail } = useAuth();
  const [verifyEmailSend, setverifyEmailSend] = useState(false);
  const history = useHistory();
  useDeepCompareEffect(() => {
    if (!user) {
      history.replace('/home');
    }
    if (user && user.emailVerified) {
      history.replace('/home');
    }
    return () => {};
  }, [user, history.location.pathname])
  const resendEmail = async () => {
    setverifyEmailSend(false);
    await sendVerificationEmail();
    setverifyEmailSend(true);
  };
  const handleLogout = () => {
    logout();
  }
  return (
    <MainLayout title="">
      <IonContent>
        {user &&
          <div style={{ textAlign: 'center', margin: "15px"}}>
            <IonText><h1>กรุณาตรวจสอบอีเมล</h1></IonText>
            <br/>
            <IonText>
              อีเมล <strong>{user!.email}</strong> ยังไม่ได้รับการยืนยันทางอีเมล<br/>
              กรุณาตรวจสอบอีเมลยืนยันการลงทะเบียนและคลิกที่ลิ้งค์ยืนยันการลงทะเบียน
            </IonText>
            <br/>
            <br/>
            {verifyEmailSend && (
              <div>
                <IonText style={{ color: 'green' }}>
                  ระบบได้ส่งอีเมลยืนยันเรียบร้อยแล้ว
                </IonText>
              </div>
            )}
            <div>
              {!verifyEmailSend && (
                <IonButton expand="block" style={{ marginTop: 10}} onClick={resendEmail}>ส่งอีเมลอีกครั้ง</IonButton>
              )}
              {verifyEmailSend && (
                <IonButton expand="block" style={{ marginTop: 10}} onClick={() => history.push('/')}>กลับสู่หน้าแรก</IonButton>
              )}
              <IonButton onClick={handleLogout} expand="block" style={{ marginTop: 10}} >ออกจากระบบ</IonButton>
            </div>
          </div>}
      </IonContent>
    </MainLayout>
  );
};

export default CheckVerifyEmail;
