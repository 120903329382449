import React from 'react'
import { IonLabel, IonSlides, IonSlide, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonButton } from '@ionic/react'
import { Link } from 'react-router-dom';
import { ManowEventInfo } from '@manow/service/dist/event';
import moment from 'moment';
import _ from 'lodash';
import { useHistory } from 'react-router';
interface Props {
  title?: string,
  events?: ManowEventInfo[]
}
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: 'auto',
  spaceBetween: 1
};



const sliceTitle = (title: string) => {
  let sliced : string = title.length > 26 ? `${title.slice(0,26)}...` : title;
  return sliced;
}

const sliceAddress = (title: string) => {
  let sliced : string = title.length > 30 ? `${title.slice(0,30)}...` : title;
  return sliced;
}


const EventList: React.FC<Props> = ({
  title = 'Event list',
  events = [],
}) => {
  const history = useHistory();
  const navigate = (path: string) => {
    history.push(path)
  }
  // const ref = useRef()
  // const eventToShow = events.filter((e) => {
  //   return !moment().isAfter(moment(e.EventEndDate!.toDate()))
  // });
  return (
    <div style={{ padding: 16, marginTop: 16, backgroundColor: 'white', }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IonLabel style={{ fontWeight: 'bold' }}>
          {title}
        </IonLabel>
        <IonButton color="light" onClick={() => navigate('/events')}>View More</IonButton>
      </div>
      <IonSlides pager={true} options={slideOpts} style={{ marginTop: 16 }}>
        {events.map(event => {
          return (<IonSlide style={{ width: 188 }}>
            <Link to={`events/${event.EventKey}`} style={{ textDecoration: 'none', width: 188}}>
              <IonCard style={{
                margin: 6,
                textAlign: 'left',
                height: 370
              }}>
                <div style={{ 
                  width: 176,
                  height: 250,
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundImage: `linear-gradient(
                    rgba(0, 0, 0, 0), 
                    rgba(0, 0, 0, 0)
                  ), url(${event.EventThumbnailImage || event.EventCoverImage}), url(${require('../../../../assets/logoManow.png')})`
                  }}></div>
                <IonCardHeader style={{padding: 6}}>
                <IonCardSubtitle style={{color:'#87cad4'}}>{moment(_.get(event, 'EventStartDate', moment()).toDate()).format('DD MM YYYY')}</IonCardSubtitle>
                  <IonCardTitle  style={{textAlign: 'left',color:'#434343', fontSize: '1rem' }}>{sliceTitle(event.EventName || '')}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent style={{padding: 6}}>
                  <IonCardSubtitle style={{color:'#A9A9A9'}}>{sliceAddress(event.EventLocationName || '')}</IonCardSubtitle>
                </IonCardContent>
              </IonCard>
            </Link>
          </IonSlide>
        )
        })}
      </IonSlides>
    </div>
  )
}

export default EventList
