import React from 'react'
import { RouteComponentProps } from 'react-router'
import { IonCard, IonButton, IonCardHeader, IonCardTitle } from '@ionic/react';
import { Link } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import Orderdetail from './components/Orderdetail';
import { OrderTransaction } from '@manow/service'
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks'

interface MatchParams {
  id: string
}
interface Props extends RouteComponentProps<MatchParams> {
}


const ShowPage: React.FC<Props> = ({ match }) => {
  const {
    id,
  } = match.params;
  const {
    user,
  } = useAuth();
  const orderTransaction = useObservable(() => OrderTransaction.GetOrderTransactionDetail(user!.uid, id));
  if (!orderTransaction) {
      return (<div>Loading</div>) // loading
  }
  console.log('show orderTransaction', orderTransaction);
  return (
    <MainLayout>
      <IonCard>
        <IonCardHeader>
            <IonCardTitle>
                {orderTransaction.OrderTransactionEventName}
            </IonCardTitle>
        </IonCardHeader>
        <Orderdetail orderTransaction={orderTransaction}/>
        <Link to="/orders">
            <IonButton expand="block">My Tickets</IonButton>
        </Link>
      </IonCard>
    </MainLayout>
  )
}

export default ShowPage;
