import React, { useState } from 'react';
import { Event } from '@manow/service';
import { useObservable } from 'rxjs-hooks'
import { IonButton } from '@ionic/react';
import { Link } from 'react-router-dom';
import { GetAllEventTicketNotExpireList } from '@manow/service/dist/event';

interface Props {
  event: Event.ManowEventInfo,
}

const BuyTicketBtn: React.FC<Props> = ({
    event,
}) => {
    const [foundTickets, setFoundTickets] = useState(false);
    const eventTickets = useObservable(() => GetAllEventTicketNotExpireList(event.EventKey!));
    debugger;
    console.log(eventTickets, 'event tickets')
    if (eventTickets) {
        const found = eventTickets.filter(e => {
            return e.EventTicketTotalAmount! > 0;
        })
        const chkFound = found.length > 0;
        if (chkFound !== foundTickets) {
            setFoundTickets(found.length > 0);
        }
    }
    let eventLink = '/events';

    return (
        <>
        {eventTickets != null && eventTickets.length > 0 && foundTickets &&
            <Link to={`${eventLink}/${event.EventKey}/manageTicket`} style={{textDecoration: 'none'}}>
                <IonButton expand="block" style={{ '--background': '#FF9F00'}}>ซื้อบัตรร่วมงาน</IonButton>
            </Link>
         } 
        </>
    )
};


export default BuyTicketBtn;
