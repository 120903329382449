import React from 'react'
import MainLayout from '../../../layout/MainLayout';
import { IonText, IonCardContent, IonCard, IonGrid, IonRow, IonCol, IonLabel, IonButton, IonAvatar } from '@ionic/react';
import { Link } from 'react-router-dom';
import { User } from '@manow/service'
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks'

interface Props {
  
}

const ProfileShowPage: React.FC<Props> = () => {
    const {
        user,
        logout
    } = useAuth();
    const userInfo = useObservable(() => User.GetUserInfoDetail(user!.uid));
    if (!userInfo) {
        return (<div>Loading</div>) // loading
    }
    const handleLogout = async () => {
        await logout();
    }
    return (
        <MainLayout title="Profile">
            <IonCard>
                <IonCardContent>
                <IonText>
                    <h1>User Profile</h1>
                </IonText>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                        <IonAvatar style={{width: '130px', height: '130px', margin: 'auto'}}>
                            <img alt="display img" src={userInfo.UserInfoDisplayImage} />
                         </IonAvatar>
                        {/* <IonImg src={userInfo.UserInfoDisplayImage} style={{borderRadius: '50%', width: '130px', margin: 'auto'}}/> */}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel><b>Display Name:</b> {userInfo.UserInfoDisplayName}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel><b>Firstname:</b> {userInfo.UserInfoFirstName}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel><b>Lastname:</b> {userInfo.UserInfoLastName}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel><b>Gender:</b> {userInfo.UserInfoGender}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel><b>Email:</b> {userInfo.UserInfoEmail}</IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Link to={`/profile/edit`}>
                    <IonButton expand="block">ตั้งค่าบัญชี</IonButton>
                </Link>
                <Link to={`/profile/interest`}>
                    <IonButton expand="block">ตั้งค่าหัวข้อที่สนใจ</IonButton>
                </Link>
                <IonButton expand="block" onClick={handleLogout}>ออกจากระบบ</IonButton>
                </IonCardContent>
            </IonCard>
        </MainLayout>
    )
}

export default ProfileShowPage
