import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useAuth } from '@manow/auth';
import { IonText, IonButton, IonCol, IonGrid, IonRow, IonImg, IonHeader, IonToolbar, IonButtons } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import SimpleForm from '../../components/form/SimpleForm';
import TextInput from '../../components/form/TextInput';
import SubmitButton from '../../components/form/SubmitButton';
import AuthLayout from './layout/AuthLayout';
import * as yup from 'yup';
import { useAsyncFn } from 'react-use';
import logo from '../../assets/logoManow.png';

interface Props extends RouteComponentProps {
  parentCallback: any
}

const Signin: React.FC<Props> = ({ history, parentCallback }) => {
  const {
    login
  } = useAuth()
  const {
    t
  } = useTranslation()
  const [state, doSubmit] = useAsyncFn(async (...args) => {
    const payload = args[0]
    await handleLogin(payload)
  })
  const handleLogin = async (formData: any) => {
    await login(formData);
    parentCallback(false);
  }
  const validationSchema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required()
  })
  const handleSignUp = async () => {
    parentCallback(false);
    setTimeout(() => {
      history.push('/auth/signup');
    }, 100);
  }    
  const closeModal = () => {
    parentCallback(false);
  }
  return (
    <AuthLayout>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={closeModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid>
        {/* <IonRow>
          <IonCol style={{ textAlign: 'right'}}>
          </IonCol>
        </IonRow> */}
        <IonRow>
          <IonCol style={{ textAlign: 'center'}}>
            <h1 style={{ fontFamily: 'Kanit !important' }}>ลงชื่อเข้าใช้</h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol />
          <IonCol>
            <IonImg src={logo} style={{height: '140px'}}/>
          </IonCol>
          <IonCol />
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" style={{ '--background': '#4267B2'}}>เข้าสู่ระบบด้วย Facebook</IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" color="light">เข้าสู่ระบบด้วยบัญชี Google</IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ textAlign: 'center'}}>
            หรือ
          </IonCol>
        </IonRow>
      </IonGrid>
      <SimpleForm onSubmit={doSubmit} validationSchema={validationSchema}>
        <TextInput type="email" name="email" placeholder={t('emailAndTel')}/>
        <TextInput type="password" name="password" placeholder={t('password')}/>
        <div style={{textAlign: 'right'}}>
          <IonButton type="button" fill="clear">
            {t('forget password')}
          </IonButton>
        </div>
        {state.error && <IonText color="danger"><small>{state.error.message}</small></IonText>}
        <SubmitButton loading={state.loading} expand="block">{t('login')}</SubmitButton>
      </SimpleForm>
      <div style={{textAlign: 'center'}}>
        <div>
          <IonText style={{display: 'inline-block', verticalAlign: 'middle'}}>
            {t('new here?')}&nbsp;
          </IonText>
          <IonText onClick={handleSignUp} style={{display: 'inline-block', verticalAlign: 'middle'}}>
            {t('sign up')}
          </IonText>
        </div>
      </div>
    </AuthLayout>
  )
}

export default withRouter(Signin)
