import React from 'react'
import { JSX } from '@ionic/core'
import { IonButton, IonSpinner } from '@ionic/react';
interface Props extends JSX.IonButton {
  loading?: boolean,
  disabled?: boolean,
}

const SubmitButton: React.FC<Props> = ({
  expand,
  children,
  loading = false,
  disabled = false,
}) => {
  const shouldDisable = disabled || loading
  return (
    <IonButton expand={expand} type="submit" disabled={shouldDisable} style={{ '--background': '#FF9F00'}}>
      {children}
      {loading && <IonSpinner />}
    </IonButton>
  )
}

export default SubmitButton
