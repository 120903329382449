import React from 'react'
import { RouteComponentProps } from 'react-router'
import { IonCardContent } from '@ionic/react';
import MainLayout from '../../layout/MainLayout';
import EventDetailHeader from './components/EventDetailHeader';
import EventDetail from './components/EventDetail';
import { useObservable } from 'rxjs-hooks';
import { GetEventDetail } from '@manow/service/dist/event';
import { useEventContext } from '../../App';
import EventBtnGroup from './components/EventBtnGroup';

interface MatchParams {
  id: string
}
interface Props extends RouteComponentProps<MatchParams> {
}

const ShowPage: React.FC<Props> = ({
  match
}) => {
  const {
    id,
  } = match.params
  const { eventInfo, updateEventInfo } = useEventContext();
  console.log('event id', id)
  const event = useObservable(() => GetEventDetail(id))
  
  if (!event) {
    return (<div>Loading</div>) // loading
  } else {
    updateEventInfo(event);
  }
  // if (user != null) {userTickets 
  //   eventLink = `/member${eventLink}`;
  // }
  console.log(event, 'event')
  console.log(eventInfo, 'event info')
  return (
    <MainLayout title="รายละเอียดงาน">
      <EventDetailHeader event={event!} />
      <EventDetail event={event!} />
      <IonCardContent>
        <EventBtnGroup event={event}/>
      </IonCardContent>
    </MainLayout>
  )
}

export default ShowPage;
