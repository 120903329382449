import React, { forwardRef, useImperativeHandle, PropsWithChildren, useRef} from 'react'
import useForm, { FormContext } from 'react-hook-form'
import * as yup from 'yup'
import {} from 'react-hook-form'
import { useDeepCompareEffect } from 'react-use';
import { FormContextValues } from 'react-hook-form/dist/contextTypes';
interface Props {
  onChange?: (formData: any) => void,
  onSubmit?: (formData: any) => Promise<any>,
  defaultValues?: object,
  validationSchema?: yup.ObjectSchemaDefinition<object>
}
const SimpleForm: React.FC<Props> = ({
  children,
  onChange = () => {},
  onSubmit = (data) => { console.log('data submitted', data)},
  defaultValues,
  validationSchema = yup.object({})
}, ref) => {
  const submitButton = useRef<any>(null)
  const methods = useForm({
    defaultValues,
    validationSchema
  })
  useImperativeHandle(ref, () => ({
    ...methods,
    submit: () => submitButton.current && submitButton.current.click()
  }))
  const formData = methods.watch([], {})
  useDeepCompareEffect(() => {
    onChange(methods.getValues({nest: true}))
    return () => {
      console.log('clean up')
    };
  }, [formData])
  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
        <button ref={submitButton} type="submit" style={{display: 'none'}}>submit</button>
      </form>
    </FormContext>
  )
}
export default forwardRef<FormContextValues, PropsWithChildren<Props>>(SimpleForm)
