import React from 'react'
import MainLayout from '../../layout/MainLayout'
import { IonContent, IonLabel } from '@ionic/react'

interface Props {
  
}
// const summaryList = [
//   {
//     src: require('../../assets/20 _ 21 - Lab คุณทัสพร จันทรี'),
//     url: 'https://drive.google.com/open?id=1si5s6aueQeaxALLS14zIS5aWZiBg3xta'
//   },
//   {
//     src: require('../../assets/20 - 1 ดร.สุวิทย์ เมษินทรีย์/20 - 1.JPG'),
//     url: 'https://drive.google.com/open?id=1BoXq_WVRG0YLoKzrrUuhytTG3CQF0nkv'
//   },
//   {
//     src: require('../../assets/20 - 2 คุณ วชิระชัย คูนำวัฒนา/20 - 2.JPG'),
//     url: 'https://drive.google.com/open?id=1gcmH3-s9zvWfSo2qyVGoJG3vCrFGwzcW'
//   },
//   {
//     src: require('../../assets/20 - 3 คุณสมชัย เลิศสุทธิวงศ์/20 - 3.JPG'),
//     url: 'https://drive.google.com/open?id=1jd9rEr-mO7QDBLmBMEqtsiPRGtSIdXPk'
//   },
//   {
//     src: require('../../assets/20 - 4 ดร.บวรนันท์ ทองกัลยา/20 - 4.JPG'),
//     url: 'https://drive.google.com/open?id=1aPYkWT6WouV4CEd4vK9ZY5ItTKW6BXtN'
//   },
//   {
//     src: require('../../assets/20 - 5 ดร.ศิริลักษณ์ เมฆสังข์/20 - 5.jpg'),
//     url: 'https://drive.google.com/open?id=1djIF-GLA0mT_mU8YDFm4tx7YBANfF53q'
//   },
//   {
//     src: require('../../assets/20 - TRACK A1/20 - TRACK A1.JPG'),
//     url: 'https://drive.google.com/open?id=1otBqAJ1QDrFYzo-IiIiNDhR-8gqO19K_'
//   },
//   {
//     src: require('../../assets/20 - TRACK A2 คุณวรวัจน์ สุวคนธ์/20 - TRACK A2.JPG'),
//     url: 'https://drive.google.com/open?id=1X03ZfcCHCL7FFI4mtPwCwoQQqY4-IgPN'
//   },
//   {
//     src: require('../../assets/20 - TRACK B1/20 - TRACK B1.JPG'),
//     url: 'https://drive.google.com/open?id=18wMJN1N4sVIJwCR03MVhh8J3rbqUiaLP'
//   },
//   {
//     src: require('../../assets/20 - TRACK B2/20 - TRACK B2.JPG'),
//     url: 'https://drive.google.com/open?id=15LGjIW2J_yZ0Y22VkHWvxbdFakU8Dg5d'
//   },
//   {
//     src: require('../../assets/20 - TRACK C1/20 - TRACK C1.JPG'),
//     url: 'https://drive.google.com/open?id=1rE98EiWhvG25VTZD6xafqxy1QYPR6Flj'
//   },
//   {
//     src: require('../../assets/20 - TRACK C2/20 - TRACK C2.JPG'),
//     url: 'https://drive.google.com/open?id=1-qRXOGBjQ1ZUmvNczFR44pxBXoE3rnvQ'
//   },
//   {
//     src: require('../../assets/20 - TRACK D1 คุณพรทิพย์ อัยยิมาพันธ์/20 - TRACK D1.JPG'),
//     url: 'https://drive.google.com/open?id=1nY18FkQSIHPuU243StGN66tdlxxxnWqe'
//   },
//   {
//     src: require('../../assets/20 - TRACK D2 คุณภคภัค/20 - TRACK D2.JPG'),
//     url: 'https://drive.google.com/open?id=1CpwfUFSbdrQLMBW68gDsfyMZEVgYqf2-'
//   },
//   {
//     src: require('../../assets/20 - TRACK INNO1/20 - TRACK INNO1.JPG'),
//     url: 'https://drive.google.com/open?id=14NyaURExhg-R_U0HE0CDIday6u506mgI'
//   },
//   {
//     src: require('../../assets/20 - TRACK INNO2/20 - TRACK INNO2.JPG'),
//     url: 'https://drive.google.com/open?id=10Xkb1lB0i3oUH4X77kTM36Hjmqe3ydxc'
//   },
//   {
//     src: require('../../assets/20 - TRACK Z Automation1 ทางรอดของธุรกิจหรือวิกฤติด้านแรงงาน/20 - TRACK Z Automation1.JPG'),
//     url: 'https://drive.google.com/open?id=1RBBPQRuJkjCjx__TEsWWrxAIb9eZbY2U'
//   },
//   {
//     src: require('../../assets/20 - TRACK Z Automation2 การกำหนดนโยบายและกลยุทธ/20 - TRACK Z Automation2.JPG'),
//     url: 'https://drive.google.com/open?id=1B9IrJPmWLcyE1UfH3z7lq5Ua5UrVbUAC'
//   },
//   {
//     src: require('../../assets/20 _ 21 - Lab คุณทัสพร จันทรี/20 _ 21 - Lab.JPG'),
//     url: 'https://drive.google.com/open?id=1AQ5jIorXaN37FTJhmaoLQOtNjNyLw_PG'
//   },
//   {
//     src: require('../../assets/21 - 1/21 - 1.JPG'),
//     url: 'https://drive.google.com/open?id=1vcJFdwfFdCj02BJfklupUKuGm91P3MhN'
//   },
//   {
//     src: require('../../assets/21 - 2/21 - 2.JPG'),
//     url: 'https://drive.google.com/open?id=1_bkuuNcpR0IFmXeEDw79v-Yq3E5XztfP'
//   },
//   {
//     src: require('../../assets/21 - TRACK E1/21 - TRACK E1.JPG'),
//     url: 'https://drive.google.com/open?id=1cJHDjTzYnqmMKwFJ_p8DW7jMDAlLaAA7'
//   },
//   {
//     src: require('../../assets/21 - TRACK E2/21 - TRACK E2.JPG'),
//     url: 'https://drive.google.com/open?id=11_gGCmWBwI5xBCIMqnm0l0PkabbNpE70'
//   },
//   {
//     src: require('../../assets/21 - TRACK E3/21 - TRACK E3.JPG'),
//     url: 'https://drive.google.com/open?id=1MPFazQ4uNLB2R1MhxGCR5zgN5kwGiX2w'
//   },
//   {
//     src: require('../../assets/21 - TRACK F1/21 - TRACK F1.JPG'),
//     url: 'https://drive.google.com/open?id=1zmvRAUne1-IlvY48Hio0iQ3u-XpsUC-i'
//   },
//   {
//     src: require('../../assets/21 - TRACK F2/21 - TRACK F2.JPG'),
//     url: 'https://drive.google.com/open?id=1pW2euhcAxwa6L-FSDHqXea2BWzJ2M5FM'
//   },
//   {
//     src: require('../../assets/21 - TRACK F3/21 - TRACK F3.JPG'),
//     url: 'https://drive.google.com/open?id=1nz47busUZXvCEgs9WLHX8bXK-KB66fld'
//   },
//   {
//     src: require('../../assets/21 - TRACK G1/21 - TRACK G1.JPG'),
//     url: 'https://drive.google.com/open?id=1ArqQey4aSlTdeVK9SAnpWiarPPXXVUlF'
//   },
//   {
//     src: require('../../assets/21 - TRACK G2/21 - TRACK G2.JPG'),
//     url: 'https://drive.google.com/open?id=1so4WP9x-RkUOfxjl-3ZHBqiyXTQD7fPi'
//   },
//   {
//     src: require('../../assets/21 - TRACK G3/21 - TRACK G3.JPG'),
//     url: 'https://drive.google.com/open?id=1zqw7jOSAU6j3YTA2N1jhNdGUOdxxjABf'
//   },
//   {
//     src: require('../../assets/21 - TRACK H1/21 - TRACK H1.JPG'),
//     url: 'https://drive.google.com/open?id=1_bkuuNcpR0IFmXeEDw79v-Yq3E5XztfP'
//   },
//   {
//     src: require('../../assets/21 - TRACK H2/21 - TRACK H2.JPG'),
//     url: 'https://drive.google.com/open?id=1iuV0hAibI78bq0cSNuUfD4mLEI0-gGjc'
//   },
//   {
//     src: require('../../assets/21 - TRACK H3/21 - TRACK H3.JPG'),
//     url: 'https://drive.google.com/open?id=1ErF7cOoX1Gpkxny5ucYMp0BKFZE44lZN'
//   },
//   {
//     src: require('../../assets/21 - TRACK INNO3/21 - TRACK INNO3.JPG'),
//     url: 'https://drive.google.com/open?id=1gkWAtURY4qIlU2pfaSBIGdE9DMY4vFGJ'
//   },
//   {
//     src: require('../../assets/21 - TRACK INNO4/21 - TRACK INNO4.JPG'),
//     url: 'https://drive.google.com/open?id=1gQ6b0L_r4apHTL2_8FfQmFpd8kqYTCWX'
//   },
//   {
//     src: require('../../assets/21 - TRACK INNO5/21 - TRACK INNO5.JPG'),
//     url: 'https://drive.google.com/open?id=11Z-bzXH44l6ui4foHUdjx5Fjw2zK46jB'
//   },
//   {
//     src: require('../../assets/21 - TRACK Z Automation3/21 - TRACK Z Automation3.JPG'),
//     url: 'https://drive.google.com/open?id=1GjjF0FNfdjbVZWYykX3q52TyLhslbkwC'
//   },
//   {
//     src: require('../../assets/21 - TRACK Z Automation4/21 - TRACK Z Automation4.JPG'),
//     url: 'https://drive.google.com/open?id=1yEahHVVr74t6RzrKdorTRXhzQeNPVVS-'
//   },
//   {
//     src: require('../../assets/21 - TRACK Z Automation5/21 - TRACK Z Automation5.JPG'),
//     url: 'https://drive.google.com/open?id=1a223P8s7UP5CwKiyw_Rs8HoTm-AKSWpH'
//   }
// ]
const DocumentList: React.FC<Props> = () => {
  return (
    <MainLayout title="Document">
      <IonContent>
        <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center', fontWeight: 'bold'}}>
          <IonLabel>
            เอกสารประกอบการบรรยาย
          </IonLabel>
        </div>
        {/* <img src={require('../../assets/movingtowardslide.jpg')} onClick={() => Browser.open({ url: 'https://drive.google.com/file/d/1z85F3tXLTyodLoVGvDFJGyhquJRoYDdK/view?usp=sharing'})}/>
        <img src={require('../../assets/Slide_Page_1.png')} onClick={() => Browser.open({ url: 'https://drive.google.com/file/d/1EAhOcx-1gmw7tIUOVrlGQyMfC62w2ZHt/view?usp=sharing'})}/>
        <img src={require('../../assets/Getting the Right Skill for the Future o.webp')} onClick={() => Browser.open({ url: 'https://drive.google.com/file/d/1WRs9LfF2VtlpOvwbnokKi3Qvv4hbqfCO/view?usp=sharing'})} /> */}
        
        <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center', fontWeight: 'bold'}}>
          <IonLabel>
            ถอดองค์ความรู้ HR Day 2019
          </IonLabel>
        </div>
        {/* {
          summaryList.map((summary) => (
            <img src={summary.src} onClick={() => Browser.open({ url: summary.url})}/>
          ))
        } */}
      </IonContent>
    </MainLayout>
  )
}

export default DocumentList
