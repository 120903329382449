import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import MainLayout from '../../../layout/MainLayout';
import { User } from '@manow/service';
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks'
import { IonGrid, IonRow, IonCol, IonText, IonButton } from '@ionic/react';
import srcMarket from '../../../assets/cate-market.png';
import srcSport from '../../../assets/cate-sport.png';
import srcArt from '../../../assets/cate-art.png';
import srcHome from '../../../assets/cate-home.png';
import srcEdu from '../../../assets/cate-edu.png';
import srcIndus from '../../../assets/cate-indus.png';
import srcCoffee from '../../../assets/cate-coffee.png';
import srcTech from '../../../assets/cate-tech.png';
import srcCheckIcon from '../../../assets/checkIcon.png';

interface Props extends RouteComponentProps {

}

const InterestPage: React.FC<Props> = () => {
  const {
    user,
  } = useAuth();
  const userInfo = useObservable(() => User.GetUserInfoDetail(user!.uid));
  if (!userInfo) {
    return (<div>Loading</div>) // loading
  }
  return (
    <MainLayout title="หัวข้อที่คุณสนใจ">
      <IonGrid>
        <IonRow>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcMarket})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 10px'
            }}>
              <IonText><h5><strong>Marketing</strong></h5></IonText>
            </div>
            <div style={{
              position: 'absolute',
              top: '6px',
              bottom: '0',
              left: '5px',
              right: '0',
              width: '158px',
              height: '100px',
              transition: '.3s ease',
              backgroundColor: 'gray',
              opacity: 0.8,
              paddingTop: '25%',
              paddingLeft: '35%',
            }}>
              <img src={srcCheckIcon} alt="icon"/>
            </div>
          </IonCol>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcSport})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 30px'
            }}>
              <IonText><h5><strong>Sport</strong></h5></IonText>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcArt})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 15px'
            }}>
              <IonText><h5><strong>Art &amp;<br /> Design</strong></h5></IonText>
            </div>
          </IonCol>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcHome})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 70px'
            }}>
              <IonText><h5><strong>Home</strong></h5></IonText>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcEdu})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 70px'
            }}>
              <IonText><h5><strong>Education</strong></h5></IonText>
            </div>
          </IonCol>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcIndus})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 15px'
            }}>
              <IonText><h5><strong>Industrial</strong></h5></IonText>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcCoffee})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 15px'
            }}>
              <IonText><h5><strong>Coffee</strong></h5></IonText>
            </div>
          </IonCol>
          <IonCol>
            <div style={{
              width: '156.5px',
              height: '107px',
              backgroundImage: `url(${srcTech})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '5px 0 0 55px'
            }}>
              <IonText><h5><strong>Technology</strong></h5></IonText>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButton expand="block" type="submit" style={{ '--background': '#FF9F00' }}>
        ตกลง
            </IonButton>
    </MainLayout>
  );
}

export default InterestPage
