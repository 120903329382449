import React from 'react';
import { ManowEventAttendee, ManowEventInfo } from '@manow/service/dist/event';
import moment from 'moment'
import { IonText, IonGrid, IonRow, IonCol, IonItem } from '@ionic/react';
import { ManowOrderTransaction } from '@manow/service/dist/orderTransaction';
import _ from 'lodash';

interface BookingSummary {
    OrderTransaction?: ManowOrderTransaction
}

interface Props {
    event: ManowEventInfo,
    bookingSummary: BookingSummary,
}

interface SummaryGroup {
    eventTicketName: string,
    eventTicketKey: string,
    quantity: number,
    attendees: ManowEventAttendee[],
    total: number,
}

const BookingSummary: React.FC<Props> = ({event , bookingSummary}) => {
    moment.locale('th');
    const orderTransaction = bookingSummary.OrderTransaction as ManowOrderTransaction;
    const summaryGroup = [] as unknown as SummaryGroup[];
    orderTransaction.EventTicketKey.map((e) => {
        const found = orderTransaction.OrderTransactionDetail.filter(o => o.OrderTransactionTicketKey === e);
        if (found && found.length > 0) {
            let price = _.get(found[0], 'OrderTransactionTicketPrice', 0)
            if (typeof price === 'string') {
                price = parseFloat(price)
                if (isNaN(price)) {
                    price = 0
                }
            }
            const tmp = {
                eventTicketKey: e,
                eventTicketName: found![0].OrderTransactionTicketName,
                quantity: found!.length,
                attendees: [],
                total: price * found!.length
            } as unknown as SummaryGroup;
            found.map((f) => {
                tmp.attendees.push({
                    EventAttendeeFirstName: f.OrderTransactionFirstName,
                    EventAttendeeLastName: f.OrderTransactionLastName
                })
                return null
            });
            summaryGroup.push(tmp);
        }
        return null
    });
    const numberStrWithCommas = (x: string) => {
        if (x) {
          return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    };
    const dStart = event.EventStartDate ? event.EventStartDate!.toDate() : Date.now();
    return (
        <div>
        {
            summaryGroup.map((s) => {
                return (
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="8">
                                <h5 style={{wordBreak:'break-word'}}><strong>{s.eventTicketName}</strong></h5>
                            </IonCol>
                            <IonCol  size="4">
                                <p style={{textAlign:'right'}}><strong>Quantity</strong></p>
                                <p style={{textAlign:'right'}}>x&nbsp;&nbsp;&nbsp;<strong style={{color: '#FF9F00'}}>{s.quantity}</strong></p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p style={{margin:'0',fontWeight:'lighter',fontSize:'14px'}}>{moment(dStart).format('DD MMMM')}</p>
                                <p style={{margin:'0',fontWeight:'lighter',fontSize:'14px'}}>{event.EventLocationName}</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonText><h5><strong>รายชื่อผู้เข้าร่วม</strong></h5></IonText>
                                {s.attendees.map(a => <p style={{fontSize:'16px'}}>{a.EventAttendeeFirstName} {a.EventAttendeeLastName}</p>)}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p style={{textAlign: 'right'}}>Total: ฿{numberStrWithCommas(s.total.toFixed(2))}</p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                )
            })
        }
        </div>
    )
}

export default BookingSummary;