import React, { useState } from 'react'
import { IonText, IonModal, IonToast } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import MainLayout from '../../layout/MainLayout';
import SimpleForm from '../../components/form/SimpleForm';
import SubmitButton from '../../components/form/SubmitButton';
// import PayPalBtn from '../../components/form/PayPalBtn';
import { useObservable } from 'rxjs-hooks';
import { useAuth } from '@manow/auth';
import { CreateOrderTransaction, ManowOrderTransaction, ManowOrderTransactionDetail } from '@manow/service/dist/orderTransaction'
import { GetEventDetail, ManowEventTicket, ManowEventInfo, GetAllEventTicketNotExpireList } from '@manow/service/dist/event';
import EventTicketList from './components/EventTicketList';
import EventTicketAttendee from './components/EventTicketAttendee';
import BookingSummaryComp from './components/BookingSummary';
import Signin from '../Auth/Signin';
import * as yup from 'yup'

interface MatchParams {
    id: string
}
interface Props extends RouteComponentProps<MatchParams> {

}
interface SelectedTicket {
    EventTicketKey?: string,
    EventTicketName? : string,
    EventTicketPrice? : string,
    Quantity?: number
}
interface BookEventAttendee {
    id: number,
    eventTicketKey: string,
    eventTicketName: string,
}
interface BookingSummary {
    OrderTransaction?: ManowOrderTransaction
    OrderTransactionDetail?: ManowOrderTransactionDetail[];
    // OrderTransactionPayPalTransactionId?: string;

}

const ManageTicketPage: React.FC<Props> = ({
    match,
    history
}) => {
    const {
      user
    } = useAuth();
    // const payPalCo
    // const payPalCompleteSubmit = useRef<HTMLIonButtonElement>(null);
    const [isChanged, setIsChanged] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [showToast, setShowToast] = useState(false);
    // const [payPalTransationId, setPayPalTransactionId] = useState('');
    const [isTicketState, setIsTicketState] = useState(true);
    const [isAttendeeState, setIsAttendeeState] = useState(false);
    const [isSummaryState, setIsSummaryState] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedTicket, setSelectedTicket] = useState<SelectedTicket[]>([]);
    const [eventAttendees, setEventAttendees] = useState<BookEventAttendee[]>([]);
    const [bookingSummary, setBookingSummary] = useState<BookingSummary>({});
    const [, doSubmitSelectTicket] = useAsyncFn(async (...args) => {
            try {
            const res = args[0];
            if (res.user == null) {
                setIsChanged(true);
                return;
            }
            const eventTickets:ManowEventTicket[] = res.eventTickets;
            const selTickets: SelectedTicket[] = [];
            const tmpEventAttendees: BookEventAttendee[] = [];
            let tempTotal = 0;
            let tempAmount = 0;
            Object.keys(res).forEach((e) => {
            //     console.log(`key=${e}  value=${res[e]}`);
                const keyArr = e.split('_');
                let value = !res[e] ? 0 : parseInt(res[e]);
                if (value && value > 0)
                {
                    let tmpSel:ManowEventTicket = {};
                    let chkAvailable = true;
                    let availableAmount = 0;
                    let availableName = '';
                    eventTickets.map(ec => {
                        if(ec.EventTicketKey === keyArr[0]) {
                            // console.log("ticket name", ec.EventTicketName);
                            tmpSel = ec;
                            const newMaxAmount = ec.EventTicketBookedAmount! + value;
                            if (newMaxAmount > ec.EventTicketTotalAmount!) {
                                chkAvailable = false;
                                availableAmount = ec.EventTicketTotalAmount! - ec.EventTicketBookedAmount!;
                                availableName = ec.EventTicketName!;
                                // console.log("set name", ec.EventTicketName);
                            }
                        }
                        return null
                    });
                    if (!chkAvailable) {
                        setShowToast(true);
                        // console.log("get name", availableName);
                        setErrorText(`${availableName} available only ${availableAmount}`);
                        setError(true);
                        return;
                    };
                    // console.log('tmpSel', tmpSel);
                    let price = !tmpSel.EventTicketPrice ? 0 : tmpSel.EventTicketPrice;
                    if (typeof price === 'string') {
                        price = parseFloat(price)
                        if (isNaN(price)) {
                            price = 0
                        }
                    }
                    tempTotal += price * value;
                    selTickets.push({
                        EventTicketKey: keyArr[0],
                        EventTicketName: tmpSel.EventTicketName,
                        EventTicketPrice: price.toFixed(2),
                        Quantity: value
                    });
                    for (let i = 0; i < value; i++) {
                        tempAmount++;
                        tmpEventAttendees.push({
                            id: tempAmount,
                            eventTicketKey: tmpSel.EventTicketKey!,
                            eventTicketName: tmpSel.EventTicketName!,
                        })
                    }
                }
            });
            if (tempAmount > 0) {
                setTotalPrice(tempTotal);
                setSelectedTicket(selTickets);
                setEventAttendees(tmpEventAttendees);
                setIsTicketState(false);
                setIsAttendeeState(true);
                setIsSummaryState(false);
            }
            return Promise.resolve();
        } catch (e) {
            console.log(e)
            return Promise.reject();
        }
    })
    const [, doSubmitAttendee] = useAsyncFn(async (...args) => {
        // console.log('doSubmitAttendee args', args[0]);
        const res = args[0];
        const event:ManowEventInfo = res.event;
        const eventTickets:ManowEventTicket[] = res.eventTickets;
        const user = res.user;
        const totalPrice = res.totalPrice
        const tmpBookingSummary: BookingSummary = {};
        const orderTransaction = {
            OrderTransactionOrderingNumber: '',
            OrderTransactionEventName: event.EventName,
            OrderTransactionEventCoverImage: event.EventCoverImage,
            OrderTransactionEventStartDate: event.EventStartDate,
            OrderTransactionEventEndDate: event.EventEndDate,
            OrderTransactionEventLocation: event.EventLocationName,
            OrderTransactionPaymentStatus: totalPrice > 0 ? 'waiting' : 'confirm',
            OrderTransactionPaymentType: totalPrice > 0 ? 'none' : 'cash',
            OrderTransactionPaymentDate: new Date(),
            OrderTransactionPaymentSummaryPrice: totalPrice,
            OrderTransactionPaymentCreditCardFee: 0,
            OrderTransactionDetail: [],
            UserKey: user.uid,
            EventKey: match.params.id,
            EventTicketKey: []
        } as unknown as ManowOrderTransaction;

        res.EventAttendee.map((e:any) => {
            const foundEventTicket = orderTransaction.EventTicketKey.find(etk => etk === e.EventTicketKey);
            if (!foundEventTicket) {
                orderTransaction.EventTicketKey.push(e.EventTicketKey);
            }
            let tmpSel:ManowEventTicket = {};
            eventTickets.map(ec => {
                if(ec.EventTicketKey === e.EventTicketKey) {
                    tmpSel = ec;
                }
                return null
            });
            const orderTransactionDetail = {
                OrderTransactionTicketName: tmpSel.EventTicketName,
                OrderTransactionTicketPrice: tmpSel.EventTicketPrice,
                OrderTransactionTicketFee: '',
                OrderTransactionTicketAmount: 1,
                OrderTransactionFirstName: e.OrderTransactionFirstName,
                OrderTransactionLastName: e.OrderTransactionLastName,
                OrderTransactionEmail: e.OrderTransactionEmail,
                OrderTransactionTel: e.OrderTransactionTel,
                OrderTransactionQRCode: '',
                OrderTransactionTicketKey: e.EventTicketKey,
              } as unknown as ManowOrderTransactionDetail;
              orderTransaction.OrderTransactionDetail.push(orderTransactionDetail);
              return null
        });
        tmpBookingSummary.OrderTransaction = orderTransaction;
        setBookingSummary(tmpBookingSummary);
        console.log(tmpBookingSummary);
        setIsTicketState(false);
        setIsAttendeeState(false);
        setIsSummaryState(true);
    });
    const event = useObservable(() => GetEventDetail(match.params.id));

    const [, doSubmitSummary] = useAsyncFn(async (...args) => {
        const res = args[0];
        const user = res.user;
        const bookingSummary = res.bookingSummary as BookingSummary;
        const orderTransaction = bookingSummary.OrderTransaction;
        CreateOrderTransaction(user.uid, orderTransaction!).subscribe(DocRes => {
            
           if(orderTransaction!.OrderTransactionPaymentSummaryPrice === 0){
            	history.push(`/orders/thanks/${DocRes.id}`);
           } 
           if(orderTransaction!.OrderTransactionPaymentSummaryPrice! > 0) {
            	history.push(`/orders/payment/${DocRes.id}?eventKey=${match.params.id}`);
           }
        });

        setIsTicketState(false);
        setIsAttendeeState(false);
        setIsSummaryState(false);
    });
    
    // const event = useObservable(() => GetEventDetail(match.params.id));
    const eventTickets = useObservable(() => GetAllEventTicketNotExpireList(match.params.id));
    
    if (!event) {
        return (<div>Loading</div>) // loading
    }
    if (!eventTickets) {
        return (<div>Loading</div>) // loading
    }
    const numberStrWithCommas = (x: string) => {
        if (x) {
          return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    };
    const callback = (val: boolean) => {
        setIsChanged(val);
      }

    // const payPalCallBack = (val: string) => {
    //     const newState : any = {...bookingSummary}
    //     newState.OrderTransaction.OrderTransactionPayPalTransactionId = val;
    //     setBookingSummary(newState)

    //     if(null !== payPalCompleteSubmit.current) {
    //         payPalCompleteSubmit.current.click();
    //     }
    // }
    // const eventTickets:EventTicketGroup[] = [
    //     {
    //         EventGroupName: 'Monday - Morning',
    //         EventTicketChildern: [
    //             { EventTicketKey: '1', EventTicketName: 'A1asdfasdfasdfasdfasfasfdsafdadsfsadf', EventTicketPrice: 1100.50 },
    //             { EventTicketKey: '2',EventTicketName: 'B1', EventTicketPrice: 1200 },
    //             { EventTicketKey: '3',EventTicketName: 'C1', EventTicketPrice: 300 }
    //         ]
    //     },
    //     {
    //         EventGroupName: 'Monday - Evening',
    //         EventTicketChildern: [
    //             { EventTicketKey: '4',EventTicketName: 'A10', EventTicketPrice: 300 },
    //             { EventTicketKey: '5',EventTicketName: 'B10', EventTicketPrice: 200 },
    //             { EventTicketKey: '6',EventTicketName: 'C10', EventTicketPrice: 100 }
    //         ]
    //     }
    // ];
    // console.log('eventAttendees', eventAttendees);
    return (
        <MainLayout title="ซื้อบัตรร่วมงาน">
            <div style={{marginTop: '60px'}}/>
            <IonText><h3>{event.EventName}</h3></IonText>
            {isTicketState && 
                <SimpleForm
                    onSubmit={(args) => { 
                        args.eventTickets = eventTickets;
                        args.user = user;
                        return doSubmitSelectTicket(args); 
                    }}
                >
                    <EventTicketList eventTickets={eventTickets}/>
                    <SubmitButton expand="block">ซื้อบัตรร่วมงาน</SubmitButton>
                </SimpleForm>
            }
            {isAttendeeState &&  <EventTicketList selectedTicket={selectedTicket}/>}
            {isAttendeeState && 
                <div>
                    <div style={{textAlign: 'right', padding: '10px'}}>
                        <IonText>Total:  ฿{numberStrWithCommas(totalPrice.toFixed(2))}</IonText>
                    </div>
                    <div style={{paddingTop: '10px'}}>
                        <IonText>กรอกรายละเอียดผู้เข้าร่วม</IonText>
                    </div>
                </div>
            }
            {isAttendeeState &&
                <SimpleForm
                    onSubmit={(args) => { 
                        args.event = event;
                        args.user = user;
                        args.eventTickets = eventTickets;
                        args.totalPrice = totalPrice;
                        return doSubmitAttendee(args); 
                    }}
                    validationSchema={yup.object().shape({
                        EventAttendee: yup.array().of(yup.object().shape({
                            EventTicketKey: yup.string().required(),
                            OrderTransactionFirstName: yup.string().label('Firstname').required(),
                            OrderTransactionLastName: yup.string().label('Lastname').required(),
                            OrderTransactionEmail: yup.string().label('Email').email().required(),
                            OrderTransactionTel: yup.string().label('Phone')
                        })).required()
                    })}
                >
                    <EventTicketAttendee eventAttendees={eventAttendees} />
                    <SubmitButton expand="block">ยืนยัน</SubmitButton>
                </SimpleForm>
            }
            {isSummaryState &&
                (<SimpleForm
                    onSubmit={(args) => { 
                        args.user = user;
                        args.bookingSummary = bookingSummary;
                        
                        console.log(args, 'args')
                        return doSubmitSummary(args); 
                    }}
                >
                    <BookingSummaryComp event={event} bookingSummary={bookingSummary}  />
                    {/* <PayPalBtn callBack={payPalCallBack} event={event} bookingSummary={bookingSummary}/> */}
                    {/* <IonButton ref={payPalCompleteSubmit} type="submit" /> */}
                    <SubmitButton expand="block">ยืนยัน</SubmitButton>
                </SimpleForm>)
            }
            <IonModal isOpen={isChanged}>
                <Signin parentCallback={callback}/>
            </IonModal>
            {error &&
            <IonToast
            color="danger"
            isOpen={showToast}
            onDidDismiss={() => {
                setErrorText('');
                setShowToast(false);
                setError(false);
            }}
            message={errorText}
            showCloseButton={true}
            duration={3000}
            />}
        </MainLayout>
    )
}

export default ManageTicketPage;
