/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { IonInput, IonButton } from '@ionic/react'
import { useAuth } from '@manow/auth';

interface Props {
  onSetMode: (mode: string) => void
}

const SignupForm: React.FC<Props> = ({
  onSetMode = () => {}
}) => {
  const [error, seterror] = useState('')
  const [credential, setcredential] = useState({
    email: '',
    password: ''
  })
  const {
    register,
    signInWithGoogle,
    signInWithFacebook,
  } = useAuth();
  const handleChange = (e: any) => {
    const {
      value = '',
      name = ''
    } = e.target
    setcredential({
      ...credential,
      [name]: value
    })
  }
  const handleRegister = async () => {
    try {
      seterror('')
      await register({
        ...credential,
        continueURL: window.location.origin
      })
    } catch (e) {
      console.log(e)
      seterror(e.message)
    }
  }
  const handleLoginGoogle = async () => {
    await signInWithGoogle();
  }
  const handleLoginFacebook = async () => {
    await signInWithFacebook();
  }
  return (
    <div>
      <IonInput name="email" placeholder="อีเมล" class="manow-input" onIonChange={handleChange}/>
      <IonInput name="password" type="password" placeholder="ตั้งรหัสผ่าน" class="manow-input" onIonChange={handleChange}/>
      <IonButton expand="block" onClick={handleRegister}>ลงทะเบียน</IonButton>
      {error && <div style={{color: 'red'}}>{error}</div>}
      <IonButton expand="block" onClick={handleLoginGoogle}>Sign in with Google</IonButton>
      <IonButton expand="block" onClick={handleLoginFacebook}>Sign in with Facebook</IonButton>
      <p>
        มีบัญชีอยู่แล้ว <a style={{textDecoration: 'none'}} onClick={() => onSetMode('signin')}>เข้าสู่ระบบ</a>
      </p>
    </div>
  )
}

export default SignupForm
