import React from 'react'
import EventList from './EventList'
import { GetAllEventList } from '@manow/service/dist/event'
import { useObservable } from 'rxjs-hooks'
import { IonSpinner } from '@ionic/react'

interface Props {
  
}

const EndedEventList: React.FC<Props> = () => {
  const eventList = useObservable(() => GetAllEventList(10, ref => ref.where('Privacy', '==', 'Public').orderBy('EventEndDate', 'desc').where('EventEndDate', '<', new Date(Date.now()))))
  if (!eventList) {
    return <IonSpinner />
  }
  return (
    <EventList title="กิจกรรมที่จบไปแล้ว" events={eventList}/>
  )
}

export default EndedEventList
