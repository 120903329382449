import React, { useState } from 'react'
import { IonModal, IonContent, IonImg} from '@ionic/react'
import manowLogo from '../assets/logoManow.png'
import SigninForm from './components/SigninForm'
import SignupForm from './components/SignupForm'

interface Props {
  show: boolean
}

const AuthModal: React.FC<Props> = ({
  show
}) => {
  // const [showModal, setshowModal] = useState(true)
  const [mode, setmode] = useState('signin')
  return (
    <IonModal backdropDismiss={false} isOpen={show} cssClass="manow-modal">
      <IonContent style={{textAlign: 'center'}} class="ion-padding">
        <h6>ยินดีต้อนรับ</h6>
        <IonImg src={manowLogo} style={{width: 180, margin: 'auto'}}/>
        {mode === 'signup' ? <SignupForm onSetMode={setmode} /> : <SigninForm onSetMode={setmode} />}
      </IonContent>
    </IonModal>
  )
}

export default AuthModal
