import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import PayPalBtn from '../../components/form/PayPalBtn';
import { useObservable } from 'rxjs-hooks';
import { useAuth } from '@manow/auth';
import { ManowOrderTransaction, ManowOrderTransactionDetail, GetOrderTransactionDetail } from '@manow/service/dist/orderTransaction'
import { GetEventDetail } from '@manow/service/dist/event';
import BookingSummaryComp from './components/BookingSummary';
import qs from 'qs'
interface MatchParams {
    id: string,
    eventKey: string
}
interface Props extends RouteComponentProps<MatchParams> {

}
interface SelectedTicket {
    EventTicketKey?: string,
    EventTicketName?: string,
    EventTicketPrice?: string,
    Quantity?: number
}
interface BookEventAttendee {
    id: number,
    eventTicketKey: string,
    eventTicketName: string,
}
interface BookingSummary {
    OrderTransaction?: ManowOrderTransaction
    OrderTransactionDetail?: ManowOrderTransactionDetail[];
    // OrderTransactionPayPalTransactionId?: string;

}

const PaymentPage: React.FC<Props> = ({
    match,
    history,
    
}) => {
    const { user } = useAuth();
    const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const { eventKey } = query;

    const {
        id,
      } = match.params;
    
    // const {eventKey, bookingSummary} = history.location.state;
    
    // const [bookingSummary, setbookingSummary] = useState({});
    // const [eventKey, seteventKey] = useState('')
    // useEffect(() => {
    //     
    //     setbookingSummary(bookingSummary)
    //     seteventKey(eventKey)
    // }, [history.location.state])
    const event = useObservable(() => GetEventDetail(eventKey));
    const orderTransaction = useObservable(() => GetOrderTransactionDetail(user!.uid, id));
    console.log(orderTransaction, 'order transaction')
    if (!orderTransaction) {
        return <div>Loading</div>
    }
    const bookingSummary = {
        OrderTransaction: orderTransaction
    } as BookingSummary;
    
    const payPalCallBack = () => {
        history.push(`/orders/thanks/${match.params.id}`);
    }
    return (
        <MainLayout title="ซื้อบัตรร่วมงาน">
            {event != null && bookingSummary != null &&
                <BookingSummaryComp event={event} bookingSummary={bookingSummary} />
            }
            {
                bookingSummary != null &&
                <PayPalBtn callback={payPalCallBack} orderKey={match.params.id} bookingSummary={bookingSummary} />

            }
        </MainLayout>
    )
}

export default PaymentPage;
