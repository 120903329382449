import React, { useState, useRef } from 'react'
import MainLayout from '../../../layout/MainLayout'
import { IonContent, IonButton, IonItem, IonItemGroup, IonItemDivider, IonLabel } from '@ionic/react'
// import profileImg from '../../../assets/profile.png'
import { useAuth } from '@manow/auth'
import { User } from '@manow/service'
import SimpleForm from '../../../components/form/SimpleForm'
import TextInput from '../../../components/form/TextInput'
import { useLocation, useHistory } from 'react-router'
import qs from 'qs'
// import { useTabContext } from '../../../components/Tab/TabContextProvider'
import { useEffectOnce } from 'react-use'
import { GetUserInfoDetail, ManowUserInfo } from '@manow/service/dist/user'
import _ from 'lodash'
import * as yup from 'yup';
import ImageInput from '../../../components/form/ImageInput'

const validationSchema = yup.object().shape({
  UserInfoEmail: yup
    .string()
    .required()
    .email(),
  UserInfoDisplayName: yup
    .string()
    .required(),
  UserInfoFirstName: yup
    .string()
    .required(),
  UserInfoLastName: yup
    .string()
    .required(),
});
interface Props {
  
}

const EditProfile: React.FC<Props> = () => {
  const { user, logout } = useAuth()
  const [userInfo, setuserInfo] = useState<ManowUserInfo>()
  useEffectOnce(() => {
    const sub = GetUserInfoDetail(_.get(user, 'uid', '')).subscribe(setuserInfo)
    return () => sub.unsubscribe()
  })
  const form = useRef<any>(null)
  const history = useHistory()
  const location = useLocation()
  const {
    uid = ''
  } = user || {}
  const submitForm = () => {
    form.current.submit()
  }
  const handleSubmit = async (profile: ManowUserInfo) => {
    // 
    await User.UpdateUserInfo(uid, JSON.parse(JSON.stringify(profile))).toPromise();
    const {
      redirect = ''
    } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    }) as any
    // hack to go back to root page of tab before redirect
    history.replace({
      pathname: '/profile'
    })
    if (redirect) {
      history.replace({
        pathname: '/'
      })
    } else {
      history.goBack()
    }
  }
  if (!userInfo) {
    return <div>Loading user info</div>
  }
  return (
    <MainLayout title="ตั้งค่าโปรไฟล์">
      <IonContent>
        <div>
          <SimpleForm ref={form} onSubmit={handleSubmit} defaultValues={userInfo} validationSchema={validationSchema}>
            <IonItemGroup>
              <ImageInput name="UserInfoDisplayImage" hideLabel={true}/>
              <div style={{textAlign: 'center'}}>
                <label>รูปโปรไฟล์</label>
              </div>
              <IonItemDivider>
                <IonLabel>ข้อมูลส่วนตัว</IonLabel>
              </IonItemDivider>
              <TextInput name="UserInfoDisplayName" placeholder="ชื่อที่แสดง" label="ชื่อที่ใช้แสดง"/>
              <TextInput name="UserInfoEmail"  placeholder="อีเมล"  label="อีเมล"/>
              <TextInput name="UserInfoFirstName"  placeholder="ชื่อจริง"  label="ชื่อ"/>
              <TextInput name="UserInfoLastName"  placeholder="นามสกุล"  label="นามสกุล"/>
              <TextInput name="UserInfoJobPosition"  placeholder="ตำแหน่ง"  label="ตำแหน่ง"/>
              <TextInput name="UserInfoPhone"  placeholder="เบอร์โทรศัพท์"  label="เบอร์โทรศัพท์"/>
            </IonItemGroup>
            <IonItemGroup>
              <IonItemDivider>
                <IonLabel>ข้อมูลบริษัท</IonLabel>
              </IonItemDivider>
              <TextInput name="UserInfoCompany"  placeholder="บริษัท/องค์กร"  label="ชื่อบริษัท/องค์กร"/>
              <TextInput name="UserInfoCompanyWebsite"  placeholder="เว็บไซต์"  label="เว็บไซต์"/>
            </IonItemGroup>
          </SimpleForm>
          <IonButton expand="block" onClick={submitForm}>บันทึก</IonButton>
          <IonItem lines="none">
            <div style={{display: 'block', width: '100%', textAlign: 'center'}} onClick={logout}>ออกจากระบบ</div>
          </IonItem>
        </div>
      </IonContent>
    </MainLayout>
  )
}

export default EditProfile
