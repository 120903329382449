import React from 'react'
import { useAuth } from '@manow/auth'
import { IonRouterOutlet } from '@ionic/react'
import { Route, RouteComponentProps } from 'react-router'
import AuthModal from '../layout/AuthModal'

import ProfileEditPage from '../pages/MemberArea/ProfilePage/EditProfile';
import InterestPage from '../pages/MemberArea/ProfilePage/InterestPage';

import ContactDetail from '../pages/Contact/ContactDetail';

import EventShowPage from '../pages/Events/ShowPage';
import EventBookingPage from '../pages/Events/BookingPage'
import EventManageTicketPage from '../pages/Events/ManageTicketPage';

import AgendaPage from '../pages/Agenda/AgendaPage';
import AgendaDetail from '../pages/Agenda/AgendaDetail';

import OrderThanksPage from '../pages/Orders/ThankPage';
import OrderShowPage from '../pages/Orders/ShowPage';
import OrderTicketPage from '../pages/Orders/TicketPage';
import OrderQr from '../pages/Orders/OrderQr';

import ExhibitorDetail from '../pages/Exhibitor/ExhibitorDetail';
import PaymentPage from './Events/PaymentPage'
import CheckVerifyEmail from './MemberArea/ProfilePage/CheckVerifyEmail';

interface Props extends RouteComponentProps {
}

const AuthenticatedApp: React.FC<Props> = ({
    match
  }) => {
  const {
    user
  } = useAuth()
  return (
    <React.Fragment>
      <AuthModal show={!user}/>
      <IonRouterOutlet>
        <Route path={`/profile/edit`} component={ProfileEditPage} exact={true}/>
        <Route path={`/profile/interest`} component={InterestPage} exact={true}/>
        <Route path={`/contactdetail/:id`} component={ContactDetail} exact={true}/>
        <Route path={`/events/:id`} component={EventShowPage} exact={true}/>
        <Route path={`/events/:id/booking`} component={EventBookingPage} exact={true}/>
        <Route path={`/events/:id/manageTicket`} component={EventManageTicketPage} exact={true}/>
        <Route path={`/orders/payment/:id`} component={PaymentPage} exact={true}/>
        <Route path={`/agenda/:id`} component={AgendaPage} exact={true}/>
        <Route path={`/agenda/:id/:eventKey`} component={AgendaDetail} exact={true}/>
        <Route path={`/orders/thanks/:id`} component={OrderThanksPage} exact={true}/>
        <Route path={`/orders/show/:id`} component={OrderShowPage} exact={true}/>
        <Route path={`/orders/ticket/:id/:orderid`} component={OrderTicketPage} exact={true}/>
        <Route path={`/orders/orderqr/:id`} component={OrderQr} exact={true}/>
        <Route path={`/exhibitor/:id`} component={ExhibitorDetail} exact={true} />
        <Route path="/profile/checkverifyemail" component={CheckVerifyEmail} exact={true} />
      </IonRouterOutlet>
    </React.Fragment>
  )
}

export default AuthenticatedApp
