import React from 'react';
import { IonGrid, IonText, IonImg, IonRow, IonCol  } from '@ionic/react';
import { useObservable } from 'rxjs-hooks';
import { Event } from '@manow/service';
import moment from 'moment'
import calendarIcon from '../../../assets/calendar-color.png';
import timeIcon from '../../../assets/time-color.png';
import pinIcon from '../../../assets/pin-color.png';

interface Props {
    eventKey: string,
}

const AgendaHeader: React.FC<Props> = ({ eventKey }) => {
    const event = useObservable(() => Event.GetEventDetail(eventKey))
    if (!event) {
        return (<div>Loading</div>) // loading
    }
    const dStart = event.EventStartDate ? event.EventStartDate!.toDate() : Date.now();
    const dEnd = event.EventEndDate ? event.EventEndDate!.toDate() : Date.now();
    return (
        <>
            <IonText style={{textAlign: 'center'}}><h2>{event!.EventName}</h2></IonText>
            <IonImg src={event.EventCoverImage}/>
            <IonGrid>
            <IonRow>
                <IonCol size="7">
                <img alt="icon" src={calendarIcon} style={{ height: '15px', marginTop: '5px', marginRight: '5px'}}/>
                {moment(dStart).format('DD/MM/YYYY')}-{moment(dEnd).format('DD/MM/YYYY')}
                </IonCol>
                <IonCol>
                <img alt="icon" src={timeIcon} style={{ height: '15px', marginTop: '5px', marginRight: '5px'}}/>
                {moment(dStart).format('HH:mm')}-{moment(dEnd).format('HH:mm')}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                <img alt="icon" src={pinIcon} style={{ height: '15px', marginTop: '5px', marginRight: '5px'}}/>
                {event.EventLocationName}
                </IonCol>
            </IonRow>
            </IonGrid>
        </>
    )
}

export default AgendaHeader;