
import React from 'react';
import { Event} from '@manow/service';
import OnEventBtn from './OnEventBtn';
import BuyTicketBtn from './BuyTicketBtn';
import EventUrlBtn from './EventUrlBtn';
// import { IonSpinner } from '@ionic/react';

interface Props {
    event: Event.ManowEventInfo,
}

const EventBtnGroup: React.FC<Props> = ({
    event,
}) => {
    // const orderTransactions = useObservable(() => OrderTransaction.GetAllOrderTransactions());
    // if (!orderTransactions) {
    //     return <IonSpinner />
    // }
    // let cnt = 0;
    // orderTransactions.forEach((o) => {
    //     if (o.EventKey === event.EventKey) {
    //         console.log('o.OrderTransactionDetail', o.OrderTransactionDetail);
    //         o.OrderTransactionDetail.forEach((d) => {
    //             cnt++;
    //         })
    //     }
    // });
    return (
        <>
            <EventUrlBtn event={event}/>
            <OnEventBtn event={event} />
            <BuyTicketBtn event={event}/>
        </>
    );
};


export default EventBtnGroup;
  