import React from 'react'

interface Props {
  
}

const NotFound: React.FC<Props> = () => {
  return (
    <div>
      Page not found
    </div>
  )
}

export default NotFound
