import React from 'react'
import { RouteComponentProps } from 'react-router'
import MainLayout from '../../layout/MainLayout';
// import { Link } from 'react-router-dom';
import { OrderTransaction } from '@manow/service';
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks';
import { IonText, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
// import GenerateQR from './components/GenerateQR';
import OrderEventDetail from './components/OrderEventDetail';

interface MatchParams {
    id: string
  }
  
interface Props extends RouteComponentProps<MatchParams>{

}

const OrderQr: React.FC<Props> = ({ match, history }) => {
    const {
        id,
    } = match.params;
    const {
        user,
    } = useAuth();
    const orderTransaction = useObservable(() => OrderTransaction.GetOrderTransactionDetail(user!.uid, id));
    if (!orderTransaction) {
        return (<div>Loading</div>) // loading
    } 
    const goBack = () => {
        history.replace('/orders');
    }
    const goPayment = () => {
        history.push(`/orders/payment/${orderTransaction.OrderTransactionKey}?eventKey=${orderTransaction.EventKey}`);
    }
    return (
        <MainLayout title="กิจกรรมของคุณ">
            <div style={{marginTop: '60px'}}></div>
            <OrderEventDetail eventKey={orderTransaction!.EventKey} showCountDown={true} />
            <IonText style={{textAlign: 'center'}}><h2>รายละเอียดการจอง</h2></IonText>
            <div style={{maxWidth: 720, margin: 'auto', padding: 20}}>
                <IonGrid>
                    {
                        (orderTransaction.OrderTransactionDetail || []).map((transaction, index) => {
                            return (
                                <IonRow>
                                    <IonCol>
                                        <IonText>
                                            <p>{`${index+1}. ${transaction.OrderTransactionFirstName} ${transaction.OrderTransactionLastName}`}</p>
                                        </IonText>
                                        <IonText>
                                            <sub>{`${transaction.OrderTransactionEmail}`}</sub>
                                        </IonText>
                                    </IonCol>
                                    <IonCol>
                                        <div style={{textAlign: 'right'}}>
                                            <IonText>
                                                <p>{transaction.OrderTransactionTicketName}</p>
                                            </IonText>
                                        </div>
                                    </IonCol>
                                </IonRow>
                            )
                        })
                    }
                </IonGrid>
                {(orderTransaction.OrderTransactionPaymentStatus === "confirm" || orderTransaction.OrderTransactionPaymentStatus === "paid" ) &&
                    <React.Fragment>
                        <IonText>
                            <h5 style={{fontWeight: 'bolder'}}>กรุณาตรวจสอบอีเมล</h5>
                        </IonText>
                        <IonText>
                            <p>ระบบได้ส่งตั๋วสำหรับกิจกรรมของท่านเข้าอีเมลของผู้เข้าร่วมงานแต่ละคนเรียบร้อยแล้ว</p>
                        </IonText>
                    </React.Fragment>
                }
                {orderTransaction.OrderTransactionPaymentStatus === "waiting" &&
                    <IonButton expand="block" style={{ '--background': '#FF9F00'}} onClick={goPayment}>ชำระเงิน</IonButton>
                }
                <IonButton expand="block" style={{ '--background': '#FF9F00'}} onClick={goBack}>ตกลง</IonButton>
            </div>
        </MainLayout>
    )
}

export default OrderQr;
