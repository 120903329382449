import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useEffectOnce } from 'react-use'
import { IonApp, setupConfig, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Plugins } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';

import { home, calendar, contact, archive, cart } from 'ionicons/icons';

import AuthenticatedApp from './pages/AuthenticatedApp';
import CheckProfile from './CheckProfile';
import ProfilePage from './pages/MemberArea/ProfilePage/ShowPage'
import EventPage from './pages/Events/ListPage';
import OrderPage from './pages/Orders/ListPage';
import NotFound from './pages/NotFound';
import HomePage from './pages/MemberArea/HomePage';
import ContactList from './pages/Contact/ContactList';
import ExhibitorPage from './pages/Exhibitor/ExhibitorList';
import DocumentPage from './pages/Document/DocumentList';
import OnEventHomePage from './pages/OnEventHome';

import { ManowEventInfo } from '@manow/service/dist/event';
import { detect } from 'detect-browser';
import warningIcon from './warning.png';
const browser = detect();

setupConfig({
  mode: 'ios'
})

const { App: AppPlugin } = Plugins;

interface ContextEvent {
  eventInfo: ManowEventInfo,
  updateEventInfo: (event: ManowEventInfo) => void
}

const EventContext = React.createContext<ContextEvent>({
  eventInfo: {},
  updateEventInfo: (event: ManowEventInfo) => {}
})

const App: React.FC = () => {
  const history = useHistory();
  // console.log('history', history);
  const [eventInfo, seteventInfo] = useState<ManowEventInfo>({});
  useEffectOnce(() => {
    AppPlugin.addListener('backButton', () => {
      try {
        history.goBack()
      } catch (e) {
        console.log(e)
      }
    })
  });

  if (browser && browser.name === 'edge') {
    return (
      <div style={{textAlign: 'center', padding: 20}}>
        <img style={{width: 120}} src={warningIcon} alt="warning" />
        <h1>
          Browser not supported
        </h1>
        <p>
          Use Google Chrome to access Manowpoint.
        </p>
        <a href="https://www.google.com/chrome/">Download Google Chrome</a>
      </div>
    )
  }

  const getDisplayMain = () => {
    let isDisplay = false;
    if (history.location.pathname.indexOf("/home") > -1 ||
      history.location.pathname.indexOf("/events") > -1 ||
      history.location.pathname.indexOf("/orders") > -1 ||
      history.location.pathname.indexOf("/profile") > -1) {
        if (history.location.pathname.indexOf("checkverifyemail") > -1) {
          isDisplay = false;
        } else {
          isDisplay = true;
        }
    }
    return isDisplay ? {display: "block"} : {display: "none"};
  }
  const getDisplayEvent = () => {
    let isDisplay = false;
    if (history.location.pathname.indexOf("/agenda") > -1 ||
      history.location.pathname.indexOf("/contact") > -1 ||
      history.location.pathname.indexOf("/exhibitor") > -1 ||
      history.location.pathname.indexOf("/document") > -1 ||
      history.location.pathname.indexOf("/eventHome") > -1) {
        isDisplay = true;
    } 
    return isDisplay ? {display: "block"} : {display: "none"};
  }
  const updateEventInfo = (event: ManowEventInfo) => {
    seteventInfo(event);
  }

  return (
    <IonApp>
      <EventContext.Provider value={{
        eventInfo: eventInfo,
        updateEventInfo: updateEventInfo
      }}>
        <CheckProfile>
          <IonTabs >
            <IonRouterOutlet>
              <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
              <Route path="/home" render={() => <HomePage />} exact={true} />
              <Route path="/profile" render={() => <ProfilePage />} exact={true}/>
              <Route path="/events" component={EventPage} exact={true}/>
              <Route path="/orders" component={OrderPage} exact={true}/>
              <Route path="/eventHome" component={OnEventHomePage} exact={true} />
              <Route path="/exhibitor" component={ExhibitorPage} exact={true}/>
              <Route path="/document" component={DocumentPage} exact={true}/>
              <Route path="/contacts" component={ContactList} exact={true}/>
              <Route path="*" component={AuthenticatedApp} />
              <Route path="/notFound" exact={true} children={<NotFound />}/>
              <Route children={<Redirect to="/notFound"/>}/>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="home" href="/home" style={getDisplayMain()}>
                <IonIcon icon={home} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>
              <IonTabButton tab="event" href="/events" style={getDisplayMain()}>
                <IonIcon icon={calendar} />
                <IonLabel>Search</IonLabel>
              </IonTabButton>
              <IonTabButton tab="order" href="/orders" style={getDisplayMain()}>
                <IonIcon icon={cart} />
                <IonLabel>My Ticket</IonLabel>
              </IonTabButton>
              <IonTabButton tab="profile" href="/profile" style={getDisplayMain()}>
                <IonIcon icon={contact} />
                <IonLabel>My Profile</IonLabel>
              </IonTabButton>
              <IonTabButton tab="eventHome" href="/eventHome" style={getDisplayEvent()}>
                <IonIcon icon={home} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>
              <IonTabButton tab="agenda" href="/agenda" style={getDisplayEvent()}>
                <IonIcon icon={calendar} />
                <IonLabel>Agenda</IonLabel>
              </IonTabButton>
              <IonTabButton tab="exhibitor" href="/exhibitor" style={getDisplayEvent()}>
                <IonIcon icon={archive} />
                <IonLabel>Exhibitors</IonLabel>
              </IonTabButton>
              <IonTabButton tab="document" href="/document" style={getDisplayEvent()}>
                <IonIcon icon={archive} />
                <IonLabel>Documents</IonLabel>
              </IonTabButton>
              <IonTabButton tab="contact" href="/contacts" style={getDisplayEvent()}>
                <IonIcon icon={contact} />
                <IonLabel>Contacts</IonLabel>
              </IonTabButton>
            </IonTabBar> 
          </IonTabs>
        </CheckProfile>
      </EventContext.Provider>
    </IonApp>
  );
}

export const useEventContext = () => {
  const context = useContext(EventContext)
  if (!context) {
    throw new Error('Config context should be use inside ConfigContextProvider')
  }
  return context
}

export default App;
