import React from 'react';
import { Event } from '@manow/service';
import { IonButton } from '@ionic/react';

interface Props {
    event: Event.ManowEventInfo,
}

const EventUrlBtn: React.FC<Props> = ({
    event,
}) => {
    // const eventTickets = useObservable(() => GetAllEventTicketNotExpireList(event.EventKey!));

    // console.log(eventTickets)w
    // if (!userTickets) {
    //     return (<div>Loading</div>); // loading
    // }

    return (
        <React.Fragment>
            {event.EventUrl != null &&
                <a rel="noopener noreferrer" target="_blank" style={{textDecoration: 'none'}} href={event.EventUrl}>
                    <IonButton expand="block">ไปยังเว็บไซต์กิจกรรม</IonButton>
                </a>
            }
        </React.Fragment>
    )
};


export default EventUrlBtn;
