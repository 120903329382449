import React, { useState } from 'react';
import { IonList, IonListHeader, IonItemSliding, IonItem, IonLabel } from '@ionic/react';
import { NavLink } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import { OrderTransaction } from '@manow/service'
import { GetOrderTransactionList } from "@manow/service/dist/orderTransaction";
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks';
import { Collapse } from 'react-collapse';
import calendarIcon from '../../assets/calendar-color.png';
import timeIcon from '../../assets/time-color.png';
import pinIcon from '../../assets/pin-color.png';
import moment from 'moment';
import { firestore } from 'firebase';

interface Props {
}

interface CollapseList {
    key: string,
    isOpen: boolean,
}

const ListPage: React.FC<Props> = () => {
    const {
      user,
    } = useAuth();
    const [isChange, setIsChange] = useState(false);
    const [collapseList, setCollapseList] = useState<CollapseList[]>([]);
    const userOrders = useObservable(() => GetOrderTransactionList(10000, (ref: firestore.Query<firestore.DocumentData>)  =>
        ref
            .where("UserKey", "==", user!.uid)
            .orderBy("OrderTransactionCreateTimestamp", "desc")
        )
    ) as OrderTransaction.ManowOrderTransaction[];
    if (!userOrders) {
        return (<div>Loading</div>) // loading
    } else {
        if ((userOrders != null && userOrders.length > 0) && collapseList.length <= 0) {
            const lstTemp = userOrders.map((u) => {
                const temp = {
                    key: u.OrderTransactionKey,
                    isOpen: false
                } as CollapseList;
                return temp
            });
            console.log('lstTemp', lstTemp);
            setCollapseList(lstTemp);
        }
    }
    const getOrderingNumber = (userTicketKey:string) => {
        let result:string = '';
        const arr:string[] = userTicketKey.split('');
        for (let i = 0; i < arr.length; i++) {
            if (((i + 1) % 4) === 0 && i !== (arr.length -1)) {
                result = `${result}${arr[i]}-`;
            } else {
                result = `${result}${arr[i]}`
            }
        }

        return result;
    }
    const setCollapse = (key: string) => {
        const temp = collapseList.find(c => c.key === key);
        if (temp) {
            temp.isOpen = !temp.isOpen;
            setCollapseList(collapseList);
            setIsChange(!isChange);
        }
    }
    const getCollapseState = (key: string) => {
        if (collapseList.length > 0) {
            const temp = collapseList.find(c => c.key === key);
            if (temp) {
                return temp.isOpen;
            }
        }
        return false;
    }
    return (
        <MainLayout title="กิจกรรมของคุณ">
            <div style={{marginTop: '40px'}}></div>
            <IonList>
                <IonListHeader><h1>การจองที่ผ่านมา</h1></IonListHeader>
                <IonItemSliding>
                {
                    userOrders.map(o => {
                        const startDateTime = o.OrderTransactionEventStartDate ? o.OrderTransactionEventStartDate!.toDate() : Date.now();
                        const endDateTime = o.OrderTransactionEventEndDate ? o.OrderTransactionEventEndDate!.toDate() : Date.now();
                        return (
                            <React.Fragment>
                            {/* <NavLink to={`/member/orders/show/${o.OrderTransactionKey}`} style={{ textDecoration: 'none' }}> */}
                                <IonItem onClick={() => {setCollapse(o.OrderTransactionKey!)}}>
                                    <img alt="eventcover" src={o.OrderTransactionEventCoverImage} style={{height: '100px', borderRadius: '5px', paddingRight: '5px'}} />
                                    <IonLabel>
                                        <h2>{o.OrderTransactionEventName}</h2>
                                        <div style={{color: '#828282', display: 'flex'}}>
                                            <div style={{fontSize: '12px', flex: 1}}>
                                                <img alt="icon" src={calendarIcon} style={{ height: '12px', marginTop: '6px', marginRight: '5px'}}/>
                                                {moment(startDateTime).format('DD/MM/YYYY')}
                                                <img alt="icon" src={timeIcon} style={{ height: '12px', marginTop: '6px', marginRight: '5px', marginLeft: '5px'}}/>
                                                {moment(startDateTime).format('HH:mm')} - {moment(endDateTime).format('HH:mm')}<br />
                                                <img alt="icon" src={pinIcon} style={{ height: '12px', marginTop: '6px', marginRight: '8px', marginLeft: '2px'}}/>
                                                {o.OrderTransactionEventLocation}
                                            </div>
                                            <div style={{flex: 1}}>
                                                <p style={{textAlign:'right', marginTop: '20px'}}>x&nbsp;<strong style={{color: '#FF9F00'}}>{o.OrderTransactionDetail.length}</strong></p>
                                            </div>
                                        </div>
                                    </IonLabel>
                                </IonItem>
                                <Collapse isOpened={getCollapseState(o.OrderTransactionKey!)}>
                                    <NavLink to={`/orders/orderqr/${o.OrderTransactionKey}`} style={{ color: '#000' }}>
                                        <div style={{paddingTop: '5px'}}>เลขที่การจองของคุณ #{getOrderingNumber(o.OrderTransactionKey!)}</div>
                                    </NavLink>
                                    {
                                        o.OrderTransactionDetail.map((ot) => {
                                            return (
                                                <div style={{paddingTop: '5px'}}>{ot.OrderTransactionFirstName} {ot.OrderTransactionLastName}</div>
                                            )
                                        })
                                    }
                                </Collapse>
                            {/* </NavLink> */}
                            </React.Fragment>
                        )
                    })
                }
                </IonItemSliding>
            </IonList>
        </MainLayout>
    )
}

export default ListPage;