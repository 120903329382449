import React from 'react'

interface Props {
  
}

const AuthLayout: React.FC<Props> = ({
  children
}) => {
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default AuthLayout
