import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en'
import th from './th'

const formatter = (lang) => {
  return Object.keys(lang).reduce((res,key) => {
    let result = {...res}
    result.translation[key] = lang[key]
    return result
  }, {
    translation: {}
  })
}
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: formatter(en),
  th: formatter(th)
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'th',
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;