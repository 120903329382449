import React, { ReactElement, forwardRef, useImperativeHandle, PropsWithChildren } from 'react'
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { IonButton } from '@ionic/react';

interface Props {
  name: string
  label?: string,
  hideButton?: boolean
}
export interface ArrayInputHandle {
  addItem: () => void
}
const ArrayInput: React.FC<Props> = ({
  children,
  name,
  label,
  hideButton = false
}, ref) => {
  const {
    getValues,
    setValue,
    register
  } = useFormContext()
  useImperativeHandle(ref, () => ({
    addItem
  }))
  const formData = getValues({nest: true})
  const values = _.get(formData, name, [{}]) as any[]
  const addItem = () => {
    register({
      name: `${name}[${values.length}]`
    })
    setValue(`${name}[${values.length}]`, {})
  }
  return (
    <React.Fragment>
      {values.map((value, index) => (
        React.Children.map(children as ReactElement<any>, (child) => React.cloneElement(child, {
          name: `${name}[${index}].${child.props.name || ''}`,
          index,
        }))
      ))}
      {!hideButton && <IonButton onClick={addItem}>Add</IonButton>}
    </React.Fragment>
  )
}

export default forwardRef<ArrayInputHandle, PropsWithChildren<Props>>(ArrayInput)
