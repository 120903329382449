import React from 'react';
import { IonList, IonItem, IonAvatar, IonLabel, IonImg, IonCardHeader, IonCardTitle } from '@ionic/react';
import { ManowEventAgenda } from '@manow/service/dist/event';

interface Props {
  eventAgendas: ManowEventAgenda[]
}

const EventSpeaker: React.FC<Props> = ({ eventAgendas }) => {
    return (
        <React.Fragment>
            <IonCardHeader>
                <IonCardTitle>
                    Speaker
                </IonCardTitle>
            </IonCardHeader>
            <IonList>
                {eventAgendas && eventAgendas.map(e => 
                    <IonItem>
                        <IonAvatar slot="start">
                            <IonImg src={e.EventAgendaSpeakerProfileImage || 'https://i.pravatar.cc/32'}/>
                        </IonAvatar>
                        <IonLabel>{e.EventAgendaSpeakerName}</IonLabel>
                    </IonItem>
                )}
            </IonList>
        </React.Fragment>
    )
}

export default EventSpeaker;