import React from 'react'
import { JSX } from '@ionic/core'
import { IonInput } from '@ionic/react';
import InputWrapper from './InputWrapper';
interface Props extends JSX.IonInput {
  name: string,
  label?: string,
  placeholder?: string,
  defaultValue?: any,
  visible?: boolean,
  required?: boolean,
}

const TextInput: React.FC<Props> = ({
  name,
  label,
  placeholder,
  defaultValue,
  visible = true,
  type = 'text',
  required = false,
  readonly = false
}) => {
  const valueGetter = (e: CustomEvent) => e.detail.value
  return (
    <InputWrapper name={name} label={label} changeEventKey="onIonChange" valueGetter={valueGetter} defaultValue={defaultValue} visible={visible}>
      <IonInput type={type} name={name} placeholder={placeholder} required={required} readonly={readonly} />
    </InputWrapper>
  )
}

export default TextInput
