import React from 'react';
import { IonCardContent, IonList, IonListHeader, IonItem, IonLabel } from '@ionic/react';
import { Link } from 'react-router-dom';
import { OrderTransaction, User} from '@manow/service';
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks'

interface Props {
  orderTransaction: OrderTransaction.ManowOrderTransaction,
}

const Orderdetail: React.FC<Props> = ({
    orderTransaction,
}) => {
    let cnt = 0;
    const {
        user,
    } = useAuth();
    const userTickets = useObservable(() => User.GetUserTicketList(user!.uid));
    if (!orderTransaction || !userTickets) {
        return (<div>Loading</div>) // loading
    }
    // console.log('userTickets', userTickets);
    // console.log('orderTransaction', orderTransaction);
    return (
        <IonCardContent>
            <IonList>
            {orderTransaction.OrderTransactionDetail.map(o => {
                // console.log('o', o);
                const found = userTickets.find(u => {
                    return u.EventKey === orderTransaction.EventKey &&
                           u.EventTicketKey === o.OrderTransactionTicketKey &&
                           u.UserKey === user!.uid &&
                           u.OrderTransactionKey === orderTransaction.OrderTransactionKey;
                });
                console.log('found', found);
                if (!found || found === null) return null;
                cnt++;
                return (
                    <Link
                        key={`${o.OrderTransactionTicketKey}${cnt}`}
                        to={`/orders/ticket/${found.UserTicketKey}/${orderTransaction.OrderTransactionKey}`}>
                        <IonListHeader>
                            Attendee#{cnt}
                        </IonListHeader>
                        <IonItem>
                            <IonLabel></IonLabel>
                            <IonLabel>
                                <h2>{o.OrderTransactionFirstName} {o.OrderTransactionLastName}</h2>
                                <h3>{o.OrderTransactionEmail} {o.OrderTransactionTel}</h3>
                                <p>{o.OrderTransactionTicketName} {o.OrderTransactionTicketPrice}</p>
                            </IonLabel>
                        </IonItem>
                    </Link>
                )
            })}
            </IonList>
        </IonCardContent>
    )
}

export default Orderdetail;