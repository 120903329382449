import React, { useState, useEffect } from 'react'
import { useDeepCompareEffect } from 'react-use'
import qs from 'qs'
import { useAuth } from '@manow/auth'
import { useHistory } from 'react-router';
import { GetUserInfoDetail, ManowUserInfo } from '@manow/service/dist/user';

const CheckProfile: React.FC = ({
  children
}) => {
  const {
    user
  } = useAuth()
  const {
    uid = ''
  } = user || {}
  const history = useHistory()
  const [userInfo, setuserInfo] = useState<ManowUserInfo>()
  useEffect(() => {
    if (uid) {
      GetUserInfoDetail(uid).subscribe(setuserInfo)
    }
  }, [uid])
  // const userInfo = useObservable<User.ManowUserInfo>(uid ? User.GetUserInfoDetail(uid) : new Observable())
  useDeepCompareEffect(() => {
    if (!user) {
      history.replace("/home");
    } else if (!user.emailVerified) {
      history.replace("/profile/checkverifyemail");
    } else {
      const profileEditUrl = '/profile/edit'
      const profileMissing = userInfo && (
        !userInfo.UserInfoDisplayName ||
        !userInfo.UserInfoFirstName ||
        !userInfo.UserInfoLastName ||
        !userInfo.UserInfoEmail
      )
      if (profileMissing && history.location.pathname !== profileEditUrl) {
        history.replace({
          pathname: profileEditUrl,
          search: qs.stringify({
            redirect: history.location.pathname
          })
        })
      }
    }
    return () => {};
  }, [userInfo, history.location.pathname])
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}

export default CheckProfile;
