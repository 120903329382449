import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { Event } from '@manow/service';
import RichtextRenderer from '../../../components/field/RichtextRenderer';

interface Props {
    eventKey: string,
}

const AgendaDescription: React.FC<Props> = ({ eventKey }) => {
    const event = useObservable(() => Event.GetEventDetail(eventKey))
    if (!event) {
        return (<div>Loading</div>) // loading
    }
    return (
        <div style={{margin: '10px'}}>
            {event!.EventDetail as any instanceof Object && (
                <RichtextRenderer value={event.EventDetail ? event!.EventDetail : ''} />
            )}
            {!(event!.EventDetail as any instanceof Object) && (
                <div
                    dangerouslySetInnerHTML={{ __html: event.EventDetail ? event!.EventDetail : '' }}
                />
            )}
        </div>
    )
}

export default AgendaDescription;