import React, { useState } from 'react';
import MainLayout from '../../layout/MainLayout';
import { IonContent, IonText, IonCard, IonCardContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import { RouteComponentProps, useHistory } from 'react-router';
import { Event } from '@manow/service';
import { useObservable } from 'rxjs-hooks';
import moment from 'moment';
import timeIcon from '../../assets/time-color.png';
import timesetIcon from '../../assets/timeset.png';
import mapIcon from '../../assets/map.png';
import starIcon from '../../assets/star.png';
import flrPlan from '../../assets/flrPlan.png';
import AgendaEventName from './components/AgendaEventName';

interface MatchParams {
    id: string,
    eventKey: string,
}

interface Props extends RouteComponentProps<MatchParams> {
}

const AgendaDetail: React.FC<Props> = ({match}) => {
    const {
        eventKey,
        id,
    } = match.params
    const history = useHistory()
    const [showMap, setShowMap] = useState(false);
    const eventAgenda = useObservable(() => Event.GetEventAgenda(eventKey, id));
    if (!eventAgenda) {
      return (<React.Fragment>Loading event info...</React.Fragment>)
    } 
    const handleShowMap = (flag:boolean) => {
        setShowMap(!flag);
    }
    const fromDate = eventAgenda.EventAgendaStartTimestamp ? eventAgenda.EventAgendaStartTimestamp.toDate() : Date.now()
    const toDate = eventAgenda.EventAgendaEndTimestamp ? eventAgenda.EventAgendaEndTimestamp.toDate() : Date.now()
    return (
        <MainLayout title="Agenda">
            <IonContent>
                <IonCard>
                    <IonCardContent>
                        <div style={{display: 'flex', textAlign:'center',position:'relative', marginBottom:'20px'}}>
                            <div style={{textAlign:'right'}}>
                                <h1><AgendaEventName eventKey={eventKey} /></h1>
                            </div>
                            <div style={{position:'absolute',right:'0'}} onClick={() => history.replace('/agenda')}>
                                <h2>X</h2>
                            </div>
                        </div>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="4">
                                    <div style={{borderRadius:'10px',backgroundColor:'#FFEAA4', margin:'0 15px 0 0', textAlign:'center'}}>
                                    <strong>Stage 1</strong>
                                    </div>
                                </IonCol>
                                <IonCol>
                                    {eventAgenda.EventAgendaTitle}
                                    <div style={{display:'flex', marginTop:'10px'}}>
                                        <img alt="icon" src={timeIcon} style={{ height: '15px', width: '15px', marginTop:'4px', marginRight: '5px'}}/>
                                        {moment(fromDate).format('HH:mm')}-{moment(toDate).format('HH:mm')}
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="4">
                                </IonCol>
                                <IonCol>
                                    <div style={{display:'flex'}}>
                                        <div style={{margin: '10px'}} onClick={()=>handleShowMap(showMap)}>
                                            <img alt="icon" src={mapIcon} style={{ height: '25px',width:'25px', marginLeft: '5px', marginBottom: '5px', cursor: 'pointer'}}/>
                                            <p style={{fontSize:'10px'}}>ผังในงาน</p>
                                        </div>
                                        <div style={{margin: '10px'}}>
                                            <img alt="icon" src={timesetIcon} style={{ height: '25px',width:'25px', marginLeft: '1px', marginBottom: '5px', cursor: 'pointer'}}/>
                                            <p style={{fontSize:'10px'}}>ตั้งเวลา</p>
                                        </div>
                                        <div style={{margin: '10px'}}>
                                            <img alt="icon" src={starIcon} style={{ height: '25px',width:'25px', marginLeft: '2px', marginBottom: '5px', cursor: 'pointer'}}/>
                                            <p style={{fontSize:'10px'}}>ติดตาม</p>
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                            {showMap &&
                            <IonRow>
                                <IonCol>
                                <img alt="icon" src={flrPlan} style={{}}/>
                                </IonCol>
                            </IonRow>
                            }
                            <IonRow>
                                <IonCol size="4">
                                    <IonText style={{textAlign:'right',fontSize:'14px'}}><strong>Speaker:</strong></IonText>
                                </IonCol>
                                <IonCol>
                                    {eventAgenda.EventAgendaSpeakerName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="4">
                                    <IonText style={{textAlign:'right',fontSize:'14px'}}><strong>Description:</strong></IonText>
                                </IonCol>
                                <IonCol>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol style={{fontSize:'14px', marginTop:'15px'}}>
                                    {eventAgenda.EventAgendaDescription}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </MainLayout>
    )
}

export default AgendaDetail;
