import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import { useAuth } from '@manow/auth';
import { useObservable } from 'react-use';
import { User } from '@manow/service';
import { Observable } from 'rxjs';


const UserDisplayName: React.FC = () => {
    const {
        user,
    } = useAuth()
    const {
        uid = undefined
      } = user || {}

    const userInfo = useObservable<User.ManowUserInfo>(uid ? User.GetUserInfoDetail(uid) : new Observable()) 
    return (
        <React.Fragment>
            {!userInfo ? <IonSkeletonText style={{ width: '60%', margin: 'auto' }}/> : <React.Fragment>{userInfo.UserInfoDisplayName}</React.Fragment>}
        </React.Fragment>
    );
}

export default UserDisplayName;