import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import MainLayout from '../../layout/MainLayout';
import { IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardContent } from '@ionic/react'
import { useHistory } from 'react-router'
import { Event } from '@manow/service';
import { useObservable } from 'rxjs-hooks';
import moment from 'moment';
import timeIcon from '../../assets/time-color.png';
import ticketIcon from '../../assets/ticket.png';
import mapIcon from '../../assets/map.png';
import starIcon from '../../assets/star.png';
import flrPlan from '../../assets/flrPlan.png';
import AgendaHeader from './components/AgendaHeader';
import AgendaDescription from './components/AgendaDescription';

interface MatchParams {
  id: string
}
interface Props extends RouteComponentProps<MatchParams> {
}

const AgendaPage: React.FC<Props> = ({
  match
}) => {
  const {
    id,
  } = match.params
  const history = useHistory()
  const [currentIdx, setCurrentIdx] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const eventAgendaList = useObservable(() => Event.GetAllEventAgendaList(id!));
  if (!eventAgendaList) {
    return (<React.Fragment>Loading event info...</React.Fragment>)
  } 
  // console.log('eventAgendaList', eventAgendaList);
  const handleNext = (idx: number) => {
    setCurrentIdx(idx + 1);
  }
  const handleBack = (idx: number) => {
    setCurrentIdx(idx - 1);
  }
  const handleShowMap = (flag:boolean) => {
    setShowMap(!flag);
  }
  const listLength = eventAgendaList.length - 1;
  const currentAgenda = eventAgendaList[currentIdx]
  const fromDate = currentAgenda && currentAgenda.EventAgendaStartTimestamp ? currentAgenda.EventAgendaStartTimestamp.toDate() : Date.now()
  return (
    <MainLayout title="Agenda">
      <IonContent>
        <AgendaHeader eventKey={id!} />
        <IonGrid>
          <IonRow>
            <IonCol>
              <img src={ticketIcon} alt="ticket" style={{ height: '25px', marginTop: '5px', marginLeft: '35px'}}/>
              บัตรเข้างาน
            </IonCol>
            <IonCol>
              <div onClick={()=>handleShowMap(showMap)}>
                <img src={mapIcon} alt="map" style={{ height: '25px', marginTop: '5px', marginLeft: '35px', cursor: 'pointer'}}/>
                ผังในงาน
              </div>
            </IonCol>
          </IonRow>
          {showMap &&
            (<IonRow>
              <IonCol>
                <img alt="floorplan" src={flrPlan}/>
              </IonCol>
            </IonRow>)
          }
        </IonGrid>
        {currentAgenda &&
          (<React.Fragment>
            <div style={{display: 'flex', height: '30px'}}>
              {currentIdx > 0 &&
                <div
                  onClick={() => handleBack(currentIdx)}
                  style={{backgroundColor: '#FFDF30',width:'10%',borderRadius:'0 20px 20px 0',paddingLeft:'0',paddingTop:'4px', textAlign:'center', cursor:'pointer'}}>
                </div>
              }
              {currentIdx === 0 &&
                <div style={{backgroundColor: '#FFDF30',width:'90%',borderRadius:'0 20px 20px 0',marginRight:'10px',paddingLeft:'15px',paddingTop:'4px'}}>
                  {moment(fromDate).format('DD/MM/YYYY')}
                </div>
              }
              {(currentIdx > 0 && currentIdx < listLength) &&
                <div style={{backgroundColor: '#FFDF30',width:'80%',borderRadius:'20px 20px 20px 20px',marginLeft:'10px',marginRight:'10px',paddingLeft:'15px',paddingTop:'4px'}}>
                  {moment(fromDate).format('DD/MM/YYYY')}
                </div>
              }
              {currentIdx === listLength &&
                <div style={{backgroundColor: '#FFDF30',width:'90%',borderRadius:'20px 0 0 20px',marginLeft:'10px',paddingLeft:'15px',paddingTop:'4px'}}>
                  {moment(fromDate).format('DD/MM/YYYY')}
                </div>
              }
              {currentIdx < listLength &&
                <div
                  onClick={() => handleNext(currentIdx)}
                  style={{backgroundColor: '#FFDF30',width:'10%',borderRadius:'20px 0 0 20px',paddingLeft:'5px',paddingTop:'4px', textAlign:'center', cursor:'pointer'}}>
                {'>'}
                </div>
              }
            </div>
            <AgendaDescription eventKey={id!} />
            {
              eventAgendaList.map((e) => {
                const fromDate = e.EventAgendaStartTimestamp ? e.EventAgendaStartTimestamp.toDate() : Date.now()
                const toDate = e.EventAgendaEndTimestamp ? e.EventAgendaEndTimestamp.toDate() : Date.now()
                return (
                  <IonCard onClick={() => history.push(`/agenda/detail/${e.EventAgendaKey}`)}>
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <div style={{borderRadius:'10px',backgroundColor:'#FFEAA4', margin:'0 15px 0 0', textAlign:'center'}}>
                              <strong>Stage 1</strong>
                            </div>
                          </IonCol>
                          <IonCol>
                            {e.EventAgendaTitle}
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <div style={{margin: '0 auto', width: '80%', display:'flex'}}>
                              <img alt="icon" src={timeIcon} style={{ height: '15px', width: '15px', marginTop:'4px', marginRight: '5px'}}/>
                              {moment(fromDate).format('HH:mm')}-{moment(toDate).format('HH:mm')}
                              <img alt="icon" src={starIcon} style={{ height: '20px', width: '20px', marginTop:'2px', marginLeft: '70px'}}/>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                )
              })
            }
          </React.Fragment>)
        }
      </IonContent>
    </MainLayout>
  )
}

export default AgendaPage