import React from 'react'
import { RouteComponentProps } from 'react-router'
import { IonButton, IonText } from '@ionic/react';
import MainLayout from '../../layout/MainLayout';
import { Link } from 'react-router-dom';
import thkSrc from '../../assets/thk.png';
import { OrderTransaction } from '@manow/service';
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks'
import OrderEventDetail from './components/OrderEventDetail';

interface MatchParams {
  id: string
}

interface Props extends RouteComponentProps<MatchParams>{
  
}

const ThanksPage: React.FC<Props> = ({ match }) => {
  const {
    id,
  } = match.params;
  const {
    user,
  } = useAuth();
  const orderTransaction = useObservable(() => OrderTransaction.GetOrderTransactionDetail(user!.uid, id));
  if (!orderTransaction) {
      return (<div>Loading</div>) // loading
  }
  const getOrderingNumber = (userTicketKey:string) => {
      let result:string = '';
      const arr:string[] = userTicketKey.split('');
      for (let i = 0; i < arr.length; i++) {
          if (((i + 1) % 4) === 0 && i !== (arr.length -1)) {
              result = `${result}${arr[i]}-`;
          } else {
              result = `${result}${arr[i]}`
          }
      }

      return result;
  }
  return (
    <MainLayout>
      <div style={{marginTop: '110px'}}></div>
      <IonText style={{textAlign: 'center'}}><h1>ขอบคุณที่ลงทะเบียนกับเรา</h1></IonText>
      <div style={{textAlign: 'center'}}>
        <img src={thkSrc} alt="thank" style={{height: '240px'}} />  
      </div>
      <OrderEventDetail eventKey={orderTransaction!.EventKey} />
      <IonText style={{textAlign: 'center'}}>
        <h6>
          เลขที่การจองของคุณ<br/>
          #{getOrderingNumber(id)}<br />
          <Link to={`/orders/orderqr/${id}`} style={{color: 'red', textDecoration: 'none'}}>
            ดูรายละเอียด
          </Link>
        </h6>
      </IonText>
      <Link to={`/orders/show/${id}`} style={{textDecoration: 'none'}}>
        <IonButton expand="block" style={{ '--background': '#FF9F00'}}>ตกลง</IonButton>
      </Link>
    </MainLayout>
  )
}

export default ThanksPage;
