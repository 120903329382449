import React from 'react';
import { IonCardTitle, IonCardHeader } from '@ionic/react';
import { ManowEventInfo } from '@manow/service/dist/event';
interface Props {
  event: ManowEventInfo
}

const EventDetailHeader: React.FC<Props> = ({ event }) => {
    return (
        <IonCardHeader
            style={{
                marginTop: '60px',
            }}
        >
            {/* <IonCardSubtitle>
                {moment(event.EventStartDate).format('DD/MM/YYYY')}
            </IonCardSubtitle> */}
            <IonCardTitle>
                {event.EventName}
            </IonCardTitle>
        </IonCardHeader>
    )
}

export default EventDetailHeader;