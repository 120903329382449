import React from 'react'
import { useFormContext } from 'react-hook-form';
import { IonItem, IonLabel } from '@ionic/react';
import { useEffectOnce } from 'react-use';
import FormErrorOutlet from './FormErrorOutlet';

interface Props {
  name: string,
  label?: string,
  defaultValue?: any,
  visible?: boolean,
  changeEventKey?: string,
  valueKey?: string,
  valueGetter?: (e: any) => any
}

const InputWrapper: React.FC<Props> = ({
  children,
  name,
  label,
  defaultValue,
  visible = true,
  changeEventKey = 'onChange',
  valueKey = 'value',
  valueGetter = (e) => e.target.value
}) => {
  const {
    register,
    setValue,
    getValues,
  } = useFormContext();

  useEffectOnce(() => {
    console.log('register', name)
    register({name})
    if (defaultValue) {
      setValue(name, defaultValue);
    }
    return () => {
      console.log('clean up')
    };
  })

  const handleChange = (e: any) => {
    const value = valueGetter(e)
    console.log('handle change', value)
    setValue(name, value)
  }

  const childProps = {
    [changeEventKey]: handleChange,
    [valueKey]: getValues()[name]
  }
  return (
    <div style={visible ? {}: {display: 'none'}}>
      <IonItem lines="none" style={{ '--border-color': '#E0E0E0', '--border-width': '1px', '--border-radius': '10px', 'marginBottom': '10px' }}>
        {label && <IonLabel position="stacked">{label}</IonLabel>}
        {React.Children.map(children, (child: any) => React.cloneElement(child, childProps))}
      </IonItem>
      <FormErrorOutlet name={name}/>
    </div>
  )
}

export default InputWrapper
