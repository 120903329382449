import React, { useState, useEffect } from 'react'
import MainLayout from '../../layout/MainLayout'
import { IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardContent } from '@ionic/react'
import { GetAllEventExhibitorList, ManowEventExhibitor } from '@manow/service/dist/event'
import { useHistory } from 'react-router'
import { useEventContext } from '../../App'

interface Props {
  
}

const ExhibitorList: React.FC<Props> = () => {
  const { eventInfo } = useEventContext()
  const history = useHistory()
  const [exhibitorList, setexhibitorList] = useState<ManowEventExhibitor[]>()
  useEffect(() => {
    if (!eventInfo || !eventInfo.EventKey) {
      return
    }
    GetAllEventExhibitorList(eventInfo.EventKey, 'asc').subscribe(setexhibitorList)
  }, [eventInfo])
  if (!exhibitorList) {
    return <div>Loading exhibitors</div>
  }
  const handleShowDetail = (key: string) => {
    history.push(`/exhibitor/${key}`)
  }
  return (
    <MainLayout title="Exhibitors">
      <IonContent>
        <IonGrid >
          <IonRow>
            {
              exhibitorList.map(exhibitor => (
                <IonCol size="6" key={exhibitor.EventExhibitorKey}>
                  <IonCard style={{margin: 0}} onClick={() => handleShowDetail(exhibitor.EventExhibitorKey!)}>
                    <img alt="company logo" src={exhibitor.EventExhibitorCompanyLogo} />
                    <IonCardContent style={{textAlign: 'center'}}>
                      <h3 style={{fontWeight: 'bolder', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{exhibitor.EventExhibitorCompanyName}</h3>
                      <p>{exhibitor.EventExhibitorBoothNumber}</p>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))
            }
          </IonRow>
        </IonGrid>
      </IonContent>
    </MainLayout>
  )
}

export default ExhibitorList
