// import 'react-dynamic-swiper/lib/styles.css'
import React from 'react'
import moment from 'moment';
import { IonList, IonText, IonSpinner } from '@ionic/react';
import EventListItem from './EventListItem';
import { Event } from '@manow/service'
import { useObservable } from 'rxjs-hooks'
// import { tap } from 'rxjs/operators';
// import MainLayout from '../../../components/layout/MainLayout';
// const {Swiper, Slide} = require('react-dynamic-swiper');
interface Props {
  
}

const EventList: React.FC<Props> = () => {
  // const slideOptions = {
  //   slidesPerView: 'auto',
  //   spaceBetween: 30,
  // }
  // const slide = useRef<HTMLIonSlidesElement>(null)
  const events = useObservable(() => Event.GetAllEventList(100, ref => ref.where('Privacy', '==', 'Public').orderBy('EventEndDate', 'desc')), [])
  // useEffect(() => {
  //   console.log('update')
  //   slide.current!.update()
  //   return () => {
  //     console.log('clean up')
  //   };
  // }, [events])
  if (!events) {
    return <IonSpinner />
  }
  const eventToShow = events.filter((e) => {
    return !moment().isAfter(moment(e.EventEndDate!.toDate()))
  });
  eventToShow.sort((a,b) => { 
    return moment(a.EventEndDate!.toDate()).isBefore(moment(b.EventEndDate!.toDate())) 
      ? -1 : moment(a.EventEndDate!.toDate()).isAfter(moment(b.EventEndDate!.toDate())) ? 1 : 0;  
  });
  return (
    <React.Fragment>
      <IonText><h1 style={{padding: 5}}>Events</h1></IonText>
      <IonList>
        {
          eventToShow.slice(0, 30).map(event => {
            return (
              <EventListItem event={event} key={event.EventKey}/>
            );
          })
        }
      </IonList>
  </React.Fragment>
  )
}

export default EventList
