import React from 'react';
import TextInput from '../../../components/form/TextInput';
import { ManowEventTicket } from '@manow/service/dist/event';
import { IonGrid, IonRow, IonCol } from '@ionic/react';

interface Props {
  eventTickets?: ManowEventTicket[],
  selectedTicket?: SelectedTicket[],
}
interface SelectedTicket {
  EventTicketKey?: string,
  EventTicketName?: string,
  EventTicketPrice?: string,
  Quantity?: number
}


const EventTicketList: React.FC<Props> = ({ eventTickets, selectedTicket }) => {
  // const numberStrWithCommas = (x: string) => {
  //   if (x) {
  //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //   }
  //   return '0';
  // };
  const mappedEventTickets = (eventTickets || []).map(ticket => {
    return {
      ...ticket,
      EventTicketPrice: (ticket.EventTicketPrice || 0)
    }
  })
  return (
    <div>
      <IonGrid style={{ padding: '0' }}>
        <IonRow>
          <IonCol size="6">
            <p><strong>Ticket</strong></p>
          </IonCol>
          <IonCol style={{ textAlign: 'center' }}>
            <p><strong>Price</strong></p>
          </IonCol>
          <IonCol size="3" style={{ textAlign: 'center' }}>
            <p><strong>Quantity</strong></p>
          </IonCol>
        </IonRow>
        {mappedEventTickets.map(e => {
            return (
              <IonRow key={e.EventTicketKey} style={{ borderBottom: '1px solid #E0E0E0' }}>
                <IonCol size="5">
                  <p>{e.EventTicketName}</p>
                </IonCol>
                <IonCol style={{ textAlign: 'right', paddingRight: '20px' }}>
                  <p>{e.EventTicketPrice}</p>
                </IonCol>
                <IonCol size="3">
                  <TextInput name={`${e.EventTicketKey}_quantity`} type="number" placeholder="0" defaultValue={0} />
                </IonCol>
              </IonRow>
            )
          })
        }
        {selectedTicket &&
          selectedTicket!.map(e => {
            return (
              <IonRow style={{ borderBottom: '1px solid #E0E0E0' }}>
                <IonCol size="5">
                  <p>{e.EventTicketName}</p>
                </IonCol>
                <IonCol style={{ textAlign: 'right', paddingRight: '20px' }}>
                  <p>฿{e.EventTicketPrice}</p>
                </IonCol>
                <IonCol size="3" style={{ textAlign: 'right', paddingRight: '20px' }}>
                  <p style={{ color: '#828282' }}>x&nbsp;&nbsp;&nbsp;{e.Quantity}</p>
                </IonCol>
              </IonRow>
            )
          })
        }
      </IonGrid>
    </div>
  )
}

export default EventTicketList;