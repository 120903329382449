import React from 'react'
import { Event} from '@manow/service';
import { useObservable } from 'rxjs-hooks'
import { IonText, IonGrid, IonRow, IonCol } from '@ionic/react';
import moment from 'moment';
import calendarIcon from '../../../assets/calendar-color.png';
import timeIcon from '../../../assets/time-color.png';
import pinIcon from '../../../assets/pin-color.png';
import Countdown from 'react-countdown-now';

interface Props {
    eventKey: string,
    showCountDown?: boolean
}

const OrderEventDetail: React.FC<Props> = ({ eventKey, showCountDown = false }) => {
    const event = useObservable(() => Event.GetEventDetail(eventKey));
    if (!event) {
        return (<div>Loading</div>) // loading
    }
    const startDateTime = event!.EventStartDate ? event!.EventStartDate!.toDate() : Date.now();
    const renderer = (e:any) => {
        // console.log('renderer e', e)
        const {
            days,
            hours,
            minutes,
            seconds,
            completed
        } = e;
        if (completed) {
          // Render a completed state
          return <span></span>;
        } else {
          // Render a countdown
          return (
              <IonGrid style={{width: '375px',paddingTop: '20px'}}>
                  <IonRow>
                      <IonCol>
                          <div style={{ margin: '0 auto', width: '50px', textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                margin: '0',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{days.toString().padStart(2, '0')}</p>
                            <p style={{
                                margin: '0',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Days</p>
                          </div>
                      </IonCol>
                      <IonCol>
                        <div style={{ margin: '0 auto',width: '50px', textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                margin: '0',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{hours.toString().padStart(2, '0')}</p>
                            <p style={{
                                margin: '0',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Hours</p>
                        </div>
                      </IonCol>
                      <IonCol>
                          <div style={{ margin: '0 auto',width: '50px', textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                margin: '0',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{minutes.toString().padStart(2, '0')}</p>
                            <p style={{
                                margin: '0',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Min</p>
                          </div>
                      </IonCol>
                      <IonCol>
                          <div style={{ margin: '0 auto',width: '50px', textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                margin: '0',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{seconds.toString().padStart(2, '0')}</p>
                            <p style={{
                                margin: '0',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Sec</p>
                          </div>
                      </IonCol>
                  </IonRow>
              </IonGrid>
          );
        }
    };
    return (
        <React.Fragment>
            <IonText style={{textAlign: 'center'}}><h3>{event.EventName}</h3></IonText>
            <div style={{margin: '0 auto', color: '#828282'}}>
                <div style={{margin: '0 auto', width: '50%', paddingLeft: '40px'}}>
                    <img alt="icon" src={calendarIcon} style={{ height: '15px', marginTop: '5px', marginRight: '5px'}}/>
                    {moment(startDateTime).format('DD/MM/YYYY')}<br />
                    <img alt="icon" src={timeIcon} style={{ height: '15px', marginTop: '5px', marginRight: '5px'}}/>
                    {moment(startDateTime).format('HH:mm')}<br />
                    <img alt="icon" src={pinIcon} style={{ height: '15px', marginTop: '5px', marginRight: '8px', marginLeft: '2px'}}/>
                    {event!.EventLocationName}
                </div>
            </div>
            {showCountDown &&
            <Countdown date={startDateTime} renderer={renderer}/>}
        </React.Fragment>
    )
}

export default OrderEventDetail;