import React from 'react';
import { IonCardContent, IonCardTitle, IonCardHeader, IonList, IonListHeader, IonItem, IonLabel  } from '@ionic/react';
import { ManowEventAgenda } from '@manow/service/dist/event';
import moment from 'moment'

interface Props {
    eventAgendas: ManowEventAgenda[],
}

const EventAgenda: React.FC<Props> = ({ eventAgendas }) => {
    // console.log('eventAgendas', eventAgendas);
    return (
        <React.Fragment>
            <IonCardHeader>
                <IonCardTitle>Agenda</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {eventAgendas && eventAgendas.map(e => {
                    let dStart,dEnd;
                    if (typeof e.EventAgendaStartTimestamp!.toDate === "function") {
                        dStart = e.EventAgendaStartTimestamp || (new Date(e.EventAgendaStartTimestamp!.toDate())).getTime() > 0 ? moment(e.EventAgendaStartTimestamp!.toDate()).format('HH:mm') : moment(Date.now()).format('HH:mm');
                    } else {
                        dStart = e.EventAgendaStartTimestamp
                    }
                    if (typeof e.EventAgendaEndTimestamp!.toDate === "function") {
                        dEnd = e.EventAgendaEndTimestamp || (new Date(e.EventAgendaEndTimestamp!.toDate())).getTime() ? moment(e.EventAgendaEndTimestamp!.toDate()).format('HH:mm') : moment(Date.now()).format('HH:mm');
                    } else {
                        dEnd = e.EventAgendaEndTimestamp;
                    }
                    return (
                        <IonList>
                            <IonListHeader style={{fontSize: 20}}>{e.EventAgendaTitle}</IonListHeader>
                            <IonItem>
                                <IonLabel>{dStart} - {dEnd}</IonLabel>
                            </IonItem>
                        </IonList>
                    )}
                )}
            </IonCardContent>
        </React.Fragment>
    )
}

export default EventAgenda;
