import React from 'react';
import { IonCardContent, IonLabel, IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import EventAgenda from './EventAgenda';
import EventSpeaker from './EventSpeaker';
import { ManowEventInfo, GetAllEventAgendaList  } from '@manow/service/dist/event';
import { useObservable } from 'rxjs-hooks';
import moment from 'moment';
import calendarIcon from '../../../assets/calendar-color.png';
import timeIcon from '../../../assets/time-color.png';
import pinIcon from '../../../assets/pin-color.png';
// import cateIcon from '../../../assets/cateIcon-color.png';
import Countdown from 'react-countdown-now';
import RichtextRenderer from '../../../components/field/RichtextRenderer';

interface Props {
  event: ManowEventInfo,
}

const EventDetail: React.FC<Props> = ({ event }) => {
    const eventAgendas = useObservable(() => GetAllEventAgendaList(event.EventKey!))
    if (!eventAgendas) {
        return (<div>Loading</div>) // loading
    }
    const coverImage = event.EventCoverImage || "https://via.placeholder.com/400x300"
    const startDateTime = event!.EventStartDate ? event!.EventStartDate!.toDate() : Date.now();
    const renderer = (e:any) => {
        // console.log('renderer e', e)
        const {
            days,
            hours,
            minutes,
            seconds,
            completed
        } = e;
        if (completed) {
          // Render a completed state
          return null;
        } else {
          // Render a countdown
          return (
              <IonGrid>
                  <IonRow>
                      <IonCol>
                          <div style={{ textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{days.toString().padStart(2, '0')}</p>
                            <p style={{
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Days</p>
                          </div>
                      </IonCol>
                      <IonCol>
                        <div style={{ textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{hours.toString().padStart(2, '0')}</p>
                            <p style={{
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Hours</p>
                        </div>
                      </IonCol>
                      <IonCol>
                          <div style={{ textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{minutes.toString().padStart(2, '0')}</p>
                            <p style={{
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Min</p>
                          </div>
                      </IonCol>
                      <IonCol>
                          <div style={{ textAlign: 'center', padding: '5px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                            <p style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#222834'
                            }}>{seconds.toString().padStart(2, '0')}</p>
                            <p style={{
                                fontWeight: 'bold',
                                color: '#828282'
                            }}>Sec</p>
                          </div>
                      </IonCol>
                  </IonRow>
              </IonGrid>
          );
        }
    };
    return (
        <IonCardContent style={{ padding: '0'}}>
            <IonImg src={coverImage}/>
            <IonCardContent style={{ padding: '0'}}>
                <IonGrid>
                    {/* <IonRow>
                        <IonCol size="4"></IonCol>
                        <IonCol>
                            <IonText style={{paddingLeft: '12px'}}>เหลือเวลาอีก</IonText>
                        </IonCol>
                    </IonRow> */}
                    <IonRow>
                        <IonCol size="4">
                            <div style={{display: 'inline-flex'}}>
                                <IonImg src={calendarIcon} style={{ height: '15px', marginTop: '3px', marginLeft: '5px'}}/>
                                <IonLabel color="light" style={{ color: '#828282' }}>
                                    {moment(startDateTime).format('DD/MM/YYYY')}
                                </IonLabel>
                            </div>
                            <div style={{display: 'inline-flex'}}>
                                <IonImg src={timeIcon} style={{ height: '15px', marginTop: '3px', marginLeft: '5px'}}/>
                                <IonLabel color="light" style={{ color: '#828282' }}>
                                    {moment(startDateTime).format('HH:mm')}
                                </IonLabel>
                            </div>
                            <div style={{display: 'inline-flex'}}>
                                <IonImg src={pinIcon} style={{ height: '15px', marginTop: '3px', marginLeft: '10px', marginRight: '5px'}}/>
                                <IonLabel color="light" style={{ color: '#828282' }}>
                                    {event!.EventLocationName}
                                </IonLabel>
                            </div>
                        </IonCol>
                        <IonCol>
                            <Countdown date={startDateTime} renderer={renderer}/>
                        </IonCol>
                    </IonRow>
                    {/* <IonRow>
                        <IonCol>
                            <div style={{ height: '30px', display: 'inline-flex', border: '1px solid #BDBDBD', borderRadius:'15px', width:'150px', paddingLeft:'10px'}}>
                                <IonImg src={cateIcon} style={{ width: '15px', height: '15px', marginTop: '6px', marginLeft: '10px', marginRight: '10px'}}/>
                                <IonLabel color="light" style={{ marginTop: '2px', color: '#4F4F4F' }}>
                                    {event!.EventCategory}
                                </IonLabel>
                            </div>
                        </IonCol>
                    </IonRow> */}
                    <IonRow>
                        <IonCol>
                            {event!.EventDetail as any instanceof Object && (
                                <p style={{color: '#828282', padding: '10px'}}>
                                    <RichtextRenderer value={event!.EventDetail ? event!.EventDetail : ''}/>
                                </p>
                            )}
                            {!(event!.EventDetail as any instanceof Object) && (
                                <div
                                    dangerouslySetInnerHTML={{ __html: event.EventDetail ? event!.EventDetail : '' }}
                                />
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
            {!!eventAgendas && eventAgendas.length !== 0 && <EventAgenda eventAgendas={eventAgendas} />}
            {!!eventAgendas && eventAgendas.length !== 0 && <EventSpeaker eventAgendas={eventAgendas} />}
            {/* <EventTicket eventKey={event.EventKey!  } /> */}
        </IonCardContent>
    )
}

export default EventDetail;