import React, { useRef, useState, useEffect } from 'react'
import { IonToast } from '@ionic/react';
import { ManowOrderTransaction, ManowOrderTransactionDetail, UpdateOrderTransaction } from '@manow/service/dist/orderTransaction';
// import { ManowEventAttendee, GetAllEventAttendeeList, UpdateEventAttendee } from '@manow/service/dist/event/eventAttendee'
import { useAuth } from '@manow/auth';
// import { useEffectOnce } from 'react-use';

import _ from 'lodash'


interface Props {
  loading?: boolean,
  disabled?: boolean,
  bookingSummary: BookingSummary,
  orderKey: string
  callback: () => void
}

interface BookingSummary {
  OrderTransaction?: ManowOrderTransaction
  OrderTransactionDetail?: ManowOrderTransactionDetail[];
}

interface Window {
  paypal?: any;
}

declare var window: Window;


const PayPalButton: React.FC<Props> = ({
  bookingSummary,
  orderKey,
  callback
}) => {
  const {
    user
  } = useAuth();
  const [showToast1, setShowToast] = useState(false)
  // const [attendeeList, setAttendeeList] = useState<ManowEventAttendee[]>([])

  // useEffectOnce(() => {
  //   GetAllEventAttendeeList(bookingSummary!.OrderTransaction!.EventKey).subscribe(resultList => {
  //     setAttendeeList(resultList);
  //   });
  // });
  
  const getPurchaseUnit = () => {
    
    const orderDetails = bookingSummary!.OrderTransaction!.OrderTransactionDetail;
    const mappedOrderDetails = orderDetails.map(detail => {
      let price = detail.OrderTransactionTicketPrice
      if (typeof price === 'string') {
        price = parseFloat(price)
      }
      return {
        ...detail,
        OrderTransactionTicketPrice: price
      }
    })
    const totalPrice = _.sumBy(mappedOrderDetails, 'OrderTransactionTicketPrice');
    const items = orderDetails.map((unit) => {
      return {
        "name": unit.OrderTransactionTicketName,
        "unit_amount": {
          "currency_code": "THB",
          "value": unit.OrderTransactionTicketPrice,
        },
        "quantity": 1,
        "currency": "THB",
      }
    })

    return {
      "purchase_units": [
        {
          "description": 'Manow Ticket Order',
          "amount":
          {
            "currency_code": "THB",
            "value": totalPrice,
            "breakdown": {
              "item_total": { "currency_code": "THB", "value": totalPrice },
            }
          },
          "items": [
            ...items
          ],
        }
      ]
    }
  }


  // const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const paypalRef = useRef<HTMLDivElement>(null);

  const getClientId = () => {
    let clientID : string;
    
    switch (process.env.REACT_APP_ENV) {
      case 'staging':
        clientID = 'AXwXijoQVMCnnE2waQPqJVVcza0ThsdliEXnwsOJSiXSCuKHxFcXfDfOGZTkDkaSbI26QdvsCHBZtAbU';
        break;
      case 'development':
        clientID = 'AXwXijoQVMCnnE2waQPqJVVcza0ThsdliEXnwsOJSiXSCuKHxFcXfDfOGZTkDkaSbI26QdvsCHBZtAbU';
        break;
      case 'production':
        clientID = 'AQfOlO41Qpev88UZvE4d_mXO0sGMPgHEihU2C3MKFwThhJkgaJ6Lidn9SShwSgnREIEi_SOB9e9B33-8';
        break;
      default:
        clientID = 'AXwXijoQVMCnnE2waQPqJVVcza0ThsdliEXnwsOJSiXSCuKHxFcXfDfOGZTkDkaSbI26QdvsCHBZtAbU';
        break;
    }
    return clientID;
  }

  const addPaypalSdk = () => {
    
    const script = document.createElement("script");
    script.type = 'text/javascript';
    const clientID =  getClientId()
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&currency=THB`
    script.addEventListener("load", () => setScriptLoaded(true));
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
    };
    script.onerror = () => {
      
      throw new Error('Paypal SDK could not be loaded.');
    };
    document.body.appendChild(script)
  }

  

  const updateOrder = (paypalTransactionId: string) => {
    UpdateOrderTransaction(user!.uid, orderKey, {
      ...bookingSummary!.OrderTransaction,
      OrderTransactionPaymentStatus: 'paid',
      OrderTransactionPayPalTransactionId: paypalTransactionId,
      OrderTransactionPaymentType: 'paypal'
    })
    // if (attendeeList.length > 0) {
    //   const eventKey = bookingSummary!.OrderTransaction!.EventKey;
    //   attendeeList.forEach(a => {
    //     const tmp = {
    //       ...a,
    //       EventAttendeeStatus: "Confirmed"
    //     };
    //     UpdateEventAttendee(eventKey, a.EventAttendeeKey!, tmp)
    //   })
    // }
  }
  useEffect(() => {
    const purchaseUnit = getPurchaseUnit();
    console.log(purchaseUnit)
    
    if (window !== undefined && window.paypal === undefined) {
      addPaypalSdk();
    } else if (
      window !== undefined &&
      window.paypal !== undefined
    ) {
      window!.paypal
        .Buttons({
          createOrder: (data: any, actions: any) => {
            return actions.order.create(purchaseUnit);
          },
          onApprove: async (data: any, actions: any) => {
            const order = await actions.order.capture();
            updateOrder(order.id)
            callback()
          },
          onError: (err: any) => {
            setError(err.message);
            setShowToast(true)
            console.error(err);
          },
        })
        .render(paypalRef.current);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);



  return (
    <div>
      {error &&
        <IonToast
          color="danger"
          isOpen={showToast1}
          onDidDismiss={() => setShowToast(false)}
          message="การชำระเงินไม่สำเร็จ กรุณาจ่ายใหม่"
          showCloseButton={true}
          // duration={1000}
        />}
     
      <div ref={paypalRef} />
      

    </div>
  );
}


export default PayPalButton



