import React from 'react';
import ReactDOM from 'react-dom';
import Setup from './Setup';
import cssVars from 'css-vars-ponyfill';
// Default values
cssVars({
  // Targets
  rootElement   : document,
  shadowDOM     : false,

  // Sources
  include       : 'link[rel=stylesheet],style',
  exclude       : '',
  variables     : {},

  // Options
  onlyLegacy    : true,
  preserveStatic: true,
  preserveVars  : false,
  silent        : false,
  updateDOM     : true,
  updateURLs    : true,
  watch         : false,

  // Callbacks
  onBeforeSend: function(xhr, elm, url) {
    // ...
  },
  onWarning: function(message) {
    // ...
  },
  onError: function(message, elm, xhr, url) {
    // ...
  },
  onSuccess: function(cssText, elm, url) {
    // ...
  },
  onComplete: function(cssText, styleElms, cssVariables, benchmark) {
    // ...
  }
});

ReactDOM.render(<Setup />, document.getElementById('root'));
