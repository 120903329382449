import React, { useState, useEffect } from 'react';
import TextInput from '../../../components/form/TextInput';
import { IonText, IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import { useAuth } from '@manow/auth';
import { GetUserInfoDetail, ManowUserInfo } from '@manow/service/dist/user';
import { useFormContext } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

interface BookEventAttendee {
  id: number,
  eventTicketName: string,
  eventTicketKey: string,
}

interface Props {
  eventAttendees: BookEventAttendee[]
}

const EventTicketAttendee: React.FC<Props> = ({ eventAttendees }) => {
  const [checked, setchecked] = useState(true)
  const [userInfo, setuserInfo] = useState<ManowUserInfo>()
  let idx = -1;
  const {
    user
  } = useAuth()
  const {
    setValue,
  } = useFormContext()
  const {
    uid = ''
  } = user || {}
  useEffect(() => {
    if (uid) {
      GetUserInfoDetail(uid).subscribe(setuserInfo)
    }
  }, [uid])
  useDeepCompareEffect(() => {
    if (checked && userInfo) {
      setValue('EventAttendee[0].OrderTransactionFirstName', userInfo.UserInfoFirstName)
      setValue('EventAttendee[0].OrderTransactionLastName', userInfo.UserInfoLastName)
      setValue('EventAttendee[0].OrderTransactionEmail', userInfo.UserInfoEmail)
      setValue('EventAttendee[0].OrderTransactionTel', userInfo.UserInfoPhone)
    }
  }, [checked, userInfo])
  const handleCheck = (e: any) => {
    const isCheck = e.detail.checked
    setchecked(isCheck)
  }
  return (
    <div>
      {
        eventAttendees.map((e) => {
          idx++;
          return (
            <div key={idx} style={{ paddingTop: '10px' }}>
              <IonText><h5>บัตรใบที่ {idx + 1}</h5></IonText>
              <IonText>{e.eventTicketName}</IonText>
              {
                idx === 0 &&
                <IonItem lines="none">
                  <IonCheckbox style={{marginRight: 10}} checked={checked} onIonChange={handleCheck} />
                  <IonLabel>ใช้ข้อมูลของฉัน</IonLabel>
                </IonItem>
              }
              <div style={{ paddingTop: '10px' }}>
                <TextInput name={`EventAttendee[${idx}].EventTicketKey`} defaultValue={e.eventTicketKey} visible={false} />
                <TextInput name={`EventAttendee[${idx}].OrderTransactionFirstName`} placeholder="ชื่อ" readonly={idx === 0 && checked}/>
                <TextInput name={`EventAttendee[${idx}].OrderTransactionLastName`} placeholder="นามสกุล" readonly={idx === 0 && checked}/>
                <TextInput name={`EventAttendee[${idx}].OrderTransactionEmail`} placeholder="อีเมล" readonly={idx === 0 && checked}/>
                <TextInput name={`EventAttendee[${idx}].OrderTransactionTel`} placeholder="เบอร์โทรศัพท์" readonly={idx === 0 && checked}/>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default EventTicketAttendee;