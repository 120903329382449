import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { Event } from '@manow/service';

interface Props {
    eventKey: string,
}

const AgendaEventName: React.FC<Props> = ({ eventKey }) => {
    const event = useObservable(() => Event.GetEventDetail(eventKey))
    if (!event) {
        return (<div>Loading</div>) // loading
    }
    return (
        <div >{event.EventName}</div>
    )
}

export default AgendaEventName;