import React, { useRef } from 'react'
import { IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import * as yup from 'yup';
import MainLayout from '../../layout/MainLayout';
import SimpleForm from '../../components/form/SimpleForm';
import SubmitButton from '../../components/form/SubmitButton';
// import { async } from 'q';
import ArrayInput, { ArrayInputHandle } from '../../components/form/ArrayInput';
import AttendeeForm from './components/AttendeeForm';
import { useObservable } from 'rxjs-hooks';
import { useAuth } from '@manow/auth';
import { CreateOrderTransaction, ManowOrderTransaction, ManowOrderTransactionDetail } from '@manow/service/dist/orderTransaction'
import { GetEventDetail } from '@manow/service/dist/event';

interface MatchParams {
  id: string
}
interface Props extends RouteComponentProps<MatchParams> {
  
}

interface Attendee {
  id: number,
  firstname: string,
  lastname: string,
}

const CreatePage: React.FC<Props> = ({
  match,
  history
}) => {
  const {
    user
  } = useAuth()
  const attendeeInput = useRef<ArrayInputHandle>(null)
  const [, doSubmit] = useAsyncFn(async (...args) => {
    // console.log('doSubmit call', args);
    // console.log('user', user);
    const event = args[0].event;
    // const orderNumber = generateOrderNumber();
    const orderTransaction = {
      OrderTransactionOrderingNumber: '',
      OrderTransactionEventName: event!.EventName,
      OrderTransactionEventCoverImage: event!.EventCoverImage,
      OrderTransactionEventStartDate: null,
      OrderTransactionEventEndDate: null,
      OrderTransactionEventLocation: '',
      OrderTransactionPaymentStatus: 'confirm',
      OrderTransactionPaymentType: 'cash',
      OrderTransactionPaymentDate: new Date(),
      OrderTransactionPaymentSummaryPrice: 0,
      OrderTransactionPaymentCreditCardFee: 0,
      OrderTransactionDetail: [],
      UserKey: user!.uid,
      EventKey: match.params.id,
      EventTicketKey: []
    } as unknown as ManowOrderTransaction;
    args[0].OrderTransactionDetail.map((p:any) => {
      const arrTickeyKey = p.EventTicketKey.split('|');
      orderTransaction.EventTicketKey.push(arrTickeyKey[0]);
      const orderTransactionDetail = {
        OrderTransactionTicketName: arrTickeyKey[1],
        OrderTransactionTicketPrice: 0,
        OrderTransactionTicketFee: '',
        OrderTransactionTicketAmount: 0,
        OrderTransactionFirstName: p.OrderTransactionFirstName,
        OrderTransactionLastName: p.OrderTransactionLastName,
        OrderTransactionEmail: p.OrderTransactionEmail,
        OrderTransactionTel: p.OrderTransactionTel,
        OrderTransactionQRCode: '',
        OrderTransactionTicketKey: arrTickeyKey[0],
      } as unknown as ManowOrderTransactionDetail;
      orderTransaction.OrderTransactionDetail.push(orderTransactionDetail);
      console.log('orderTransaction', orderTransaction);
      // const eventAttendee = {
      //   EventAttendeeFirstName: p.OrderTransactionFirstName,
      //   EventAttendeeLastName: p.OrderTransactionLastName,
      //   EventAttendeeEmail: p.OrderTransactionEmail,
      //   EventKey: match.params.id,
      // } as ManowEventAttendee;
      // console.log('eventAttendee', eventAttendee);
      // CreateEventAttendee(match.params.id, eventAttendee);
      // if (user!.email.toLowerCase() == orderTransactionDetail.OrderTransactionEmail.toLowerCase()) {
      //   const userTicket = {
      //     UserTicketOrderingNumber: orderNumber,
      //     UserTicketName: arrTickeyKey[1],
      //     UserTicketFirstName: p.OrderTransactionFirstName,
      //     UserTicketLastName: p.OrderTransactionLastName,
      //     UserTicketEmail: p.OrderTransactionEmail,
      //     UserKey: user!.uid,
      //     EventKey: match.params.id,
      //     EventTicketKey: arrTickeyKey[0]
      //   } as unknown as ManowUserTicket;
      //   console.log('userTicket', userTicket);
      //   CreateUserTicket(user!.uid, userTicket);
      // }
      return null
    });
    CreateOrderTransaction(user!.uid, orderTransaction).subscribe(DocRes => {
      console.log('DocRes', DocRes);
      history.push(`/orders/thanks/${DocRes.id}`);
    });
  })
  const event = useObservable(() => GetEventDetail(match.params.id));
  if (!event) {
    return (<div>Loading</div>) // loading
  }
  const validationSchema = yup.object({
    // EventAttendeeFirstName: yup.string().required(),
    // EventAttendeeLastName: yup.string().required(),
  })
  const addAttendee = () => {
    attendeeInput.current!.addItem()
  }
  return (
    <MainLayout title="Create booking">
       <SimpleForm
        validationSchema={validationSchema}
        onSubmit={(args) => { 
          args.event = event;
          return doSubmit(args); 
        }}
      >
          <ArrayInput ref={attendeeInput} name="OrderTransactionDetail" hideButton={true}>
            <AttendeeForm eventKey={match.params.id} />
          </ArrayInput>
          <IonButton expand="block" onClick={addAttendee}>Add more attendee</IonButton>
          <SubmitButton expand="block">Confirm</SubmitButton>
        </SimpleForm>
    </MainLayout>
  )
}

export default CreatePage;
