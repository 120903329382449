import React, { useRef } from 'react'
import MainLayout from '../../layout/MainLayout';
import { IonText, IonSlide, IonSlides } from '@ionic/react';
import EventList from './components/EventList';

// const {Swiper, Slide} = require('react-dynamic-swiper');

interface Props {
  
}

const ListPage: React.FC<Props> = () => {
  const slideOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
  };
  const slide = useRef<HTMLIonSlidesElement>(null);
  const categories = [
    {id: 1, name: 'Technology', backgroundImage: require('../../assets/techCat.jpg')},
    {id: 2, name: 'Craft', backgroundImage: require('../../assets/craftCat2.jpg')},
    {id: 3, name: 'Music', backgroundImage: require('../../assets/musicCat.jpg')},
    {id: 4, name: 'Education', backgroundImage: require('../../assets/educationCat.jpg')}
  ];
  // const coverImage = "https://via.placeholder.com/200x160";
  return (
    <MainLayout title="Manowpoint">
      <React.Fragment>
        <IonText><h1 style={{padding: 5}}>Categories</h1></IonText>
        <IonSlides ref={slide} options={slideOptions} pager={false}>
          {
            categories.map(c => {
              return (
                <IonSlide key={c.id!} style={{
                  width: '200px',
                  height: '160px',
                  backgroundImage: `
                    linear-gradient(
                      rgba(0, 0, 0, 0.5), 
                      rgba(0, 0, 0, 0.1)
                    ),
                    url(${c.backgroundImage})
                  `,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  margin: '10px',
                  borderRadius: '25px'
                }}>
                  <IonText>
                    <h2 style={{ margin: '15px', color: 'white'}}><strong>{c.name}</strong></h2>
                  </IonText>
                </IonSlide>);
            })
          }
          </IonSlides>
        <EventList />
      </React.Fragment>
    </MainLayout>
  )
}

export default ListPage;
