import React from 'react';
import { Event, User} from '@manow/service';
import { useAuth } from '@manow/auth';
import { useObservable } from 'rxjs-hooks'
import { IonButton } from '@ionic/react';
import { Link } from 'react-router-dom';
import { ManowUserTicket } from '@manow/service/dist/user';
import { from } from 'rxjs';

interface Props {
  event: Event.ManowEventInfo,
}

const OnEventBtn: React.FC<Props> = ({
    event,
}) => {
    const {
        user,
    } = useAuth();
    const userTickets = useObservable(() => {
        if (!user) {
            const dummy: ManowUserTicket[] = []
            return from(Promise.resolve(dummy))
        }
        return User.GetUserTicketList(user!.uid)
    });
    if (!userTickets) {
        return (<div>Loading</div>); // loading
    }
    console.log('userTickets', userTickets);
    const userTicket = userTickets!.find(u => u.EventKey! === event.EventKey);
    console.log('userTicket', userTicket);
    return (
        <>
        {userTicket != null &&
            <Link to={`/agenda/${event!.EventKey}`}>
                <IonButton expand="block">รายละเอียดเพิ่มเติม</IonButton>
            </Link>
        }
        </>
    )
};


export default OnEventBtn;
