import React from 'react'
import TextInput from '../../../components/form/TextInput';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSelect, IonSelectOption } from '@ionic/react';
import { GetAllEventTicketNotExpireList  } from '@manow/service/dist/event';
import { useObservable } from 'rxjs-hooks';
import InputWrapper from '../../../components/form/InputWrapper';
import moment from 'moment';

interface Props {
  name?: string,
  index?: number,
  eventKey: string,
}

const AttendeeForm: React.FC<Props> = ({
  name = '',
  index = 0,
  eventKey
}) => {
  const eventTickets = useObservable(() => GetAllEventTicketNotExpireList(eventKey));
  if (!eventTickets) {
    return (<div>Loading</div>) // loading
  }
  const valueGetter = (e: CustomEvent) => e.detail.value
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          Attendee #{index + 1}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <TextInput name={`${name}OrderTransactionFirstName`} label="First Name" placeholder="First Name"/>
        <TextInput name={`${name}OrderTransactionLastName`} label="Last Name" placeholder="Last Name"/>
        <TextInput name={`${name}OrderTransactionEmail`} label="Email" placeholder="Email"/>
        <TextInput name={`${name}OrderTransactionTel`} label="Phone" placeholder="Phone"/>
        <InputWrapper name={`${name}EventTicketKey`} label="Ticket" changeEventKey="onIonChange" valueGetter={valueGetter}>
          <IonSelect name={`${name}EventTicketKey`} placeholder="Ticket list">
            {eventTickets.map(et => {
              const dExpire = et.EventTicketExpiryDate ? et.EventTicketExpiryDate!.toDate() : Date.now();
              return (
                <IonSelectOption value={`${et.EventTicketKey}|${et.EventTicketName}`}>{et.EventTicketName} {et.EventTicketPrice} ({moment(dExpire).format('MMM Do, YYYY')})</IonSelectOption>
              )
            })
            }
          </IonSelect>
        </InputWrapper>
      </IonCardContent>
    </IonCard>
  )
}

export default AttendeeForm
