import React, { useState } from 'react';
import MainLayout from '../../layout/MainLayout';
import { IonContent, IonLabel, IonItem, IonList, IonImg, IonAvatar } from '@ionic/react';
import { useEffectOnce } from 'react-use';
import { GetUserBusinessCardDetail, ManowUserBusinessCard, GetUserInfoDetail } from '@manow/service/dist/user';
import { useAuth } from '@manow/auth';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router';

interface RouteParams {
  id: string
}
interface Props extends RouteComponentProps<RouteParams> {

}

const ContactDetail: React.FC<Props> = ({ match }) => {
  const [businessCardInfo, setbusinessCardInfo] = useState<ManowUserBusinessCard>()
  const {
    user
  } = useAuth();
  useEffectOnce(() => {
    GetUserBusinessCardDetail(_.get(user, 'uid', ''), match.params.id).subscribe(async (d) => {
      console.log(d)
      if (d.UserBusinessCardUserKey || d.UserBusinessCardEventExhibitorKey) {
        setbusinessCardInfo(d)
      } else {
        const sub = await GetUserInfoDetail(match.params.id).subscribe(userinfo => {
          const bizCard = {
            // UserBusinessCardKey: userinfoSnap.id,
            // UserBusinessCardEventKey: EventKey,
            UserBusinessCardCompanyName: userinfo.UserInfoCompany || '',
            UserBusinessCardCompanyLogo: userinfo.UserInfoCompanyLogo || userinfo.UserInfoDisplayImage || '',
            UserBusinessCardEmail: userinfo.UserInfoEmail || '',
            UserBusinessCardFirstName: userinfo.UserInfoFirstName || '',
            UserBusinessCardLastName: userinfo.UserInfoLastName || '',
            UserBusinessCardTelNumber: userinfo.UserInfoPhone || '',
            UserBusinessCardCompanyAddress: userinfo.UserInfoCompanyAddress || '',
            UserBusinessCardWebsite: userinfo.UserInfoCompanyWebsite || '',
            UserBusinessCardPosition: userinfo.UserInfoJobPosition || '',
            // UserBusinessCardUserKey: userinfoSnap.id,
          }
          setbusinessCardInfo(bizCard)
          sub.unsubscribe()
        })
      }
    })
  });
  if (!businessCardInfo) {
    return <div>Loading business card</div>
  }
  return (
    <MainLayout title="ข้อมูลผู้ติดต่อ">
      <IonContent>
        <IonList>
          <IonItem>
            <IonAvatar style={{ width: 120, height: 120, margin: 'auto', marginTop: 20, marginBottom: 20 }}>
              <IonImg src={businessCardInfo.UserBusinessCardCompanyLogo} />
            </IonAvatar>
          </IonItem>
          <IonItem>
            <IonLabel>ชื่อ สกุล</IonLabel>
            {businessCardInfo.UserBusinessCardFirstName} {businessCardInfo.UserBusinessCardLastName}
          </IonItem>
          <IonItem>
            <IonLabel>อีเมล</IonLabel>
            {businessCardInfo.UserBusinessCardEmail}
          </IonItem>
          <IonItem>
            <IonLabel>ตำแหน่ง</IonLabel>
            {businessCardInfo.UserBusinessCardPosition}
          </IonItem>
          <IonItem>
            <IonLabel>โทรศัพท์</IonLabel>
            {businessCardInfo.UserBusinessCardTelNumber}
          </IonItem>
          <IonItem>
            <IonLabel>บริษัท</IonLabel>
            {businessCardInfo.UserBusinessCardCompanyName}
          </IonItem>
          <IonItem>
            <IonLabel>เว็บไซต์บริษัท</IonLabel>
            {businessCardInfo.UserBusinessCardWebsite}
          </IonItem>
        </IonList>
      </IonContent>
    </MainLayout>
  )
}

export default ContactDetail;
