import React from 'react'
import { IonReactRouter } from '@ionic/react-router'
import { AuthProvider, client } from '@manow/auth'
import App from './App'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n';

interface Props {
  
}
const Setup: React.FC<Props> = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider client={client}>
        <IonReactRouter>
          <App />
        </IonReactRouter>
      </AuthProvider>
    </I18nextProvider>
  )
}

export default Setup
