import React, { useState } from 'react'
import MainLayout from '../../layout/MainLayout'
import { useEffectOnce } from 'react-use'
import { GetEventExhibitorDetail, ManowEventExhibitor } from '@manow/service/dist/event'
import { RouteComponentProps } from 'react-router'
import { IonContent, IonList, IonItem, IonImg, IonItemDivider, IonLabel } from '@ionic/react'
import { useEventContext } from '../../App'

interface RouteParams {
  id: string
}
interface Props extends RouteComponentProps<RouteParams> {

}

const ExhibitorDetail: React.FC<Props> = ({ match }) => {
  const { eventInfo } = useEventContext();
  const [exhibitor, setexhibitor] = useState<ManowEventExhibitor>();
  useEffectOnce(() => {
    GetEventExhibitorDetail(eventInfo.EventKey!, match.params.id).subscribe(setexhibitor)
  })
  if (!exhibitor) {
    return <div>Loading exhibitor info</div>
  }
  return (
    <MainLayout title={exhibitor.EventExhibitorCompanyName}>
      <IonContent>
        <IonList>
          <IonItem>
            <IonImg src={exhibitor.EventExhibitorCompanyLogo} />
          </IonItem>
          <IonItem>
            <IonLabel>หมายเลขบูท</IonLabel>
            {exhibitor.EventExhibitorBoothNumber}
          </IonItem>
          <IonItem>
            <IonLabel>บริษัท</IonLabel>
            {exhibitor.EventExhibitorCompanyName}
          </IonItem>
          <IonItemDivider>
            รายละเอียดผู้ติดต่อ
          </IonItemDivider>
          <IonItem>
            <IonLabel>ชื่อ</IonLabel>
            {exhibitor.EventExhibitorContactPerson}
          </IonItem>
          <IonItem>
            <IonLabel>อีเมล</IonLabel>
            {exhibitor.EventExhibitorContactEmail}
          </IonItem>
          <IonItem>
            <IonLabel>เบอร์โทรศัพท์</IonLabel>
            {exhibitor.EventExhibitorContactNumber}
          </IonItem>
        </IonList>
      </IonContent>
    </MainLayout>
  )
}

export default ExhibitorDetail
