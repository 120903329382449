import { IonContent, IonImg, IonButton } from '@ionic/react';
import React from 'react';
import MainLayout from '../layout/MainLayout';
import homeGraphic from '../assets/homeGraphic.png';
import { useAuth } from '@manow/auth';
import { useAsync } from 'react-use';
import { useHistory } from 'react-router';
import { useEventContext } from '../App';
import QRCode from 'qrcode.react';
import { User, Event } from '@manow/service';
import UserDisplayName from '../components/UserDisplayName';
import RichtextRenderer from '../components/field/RichtextRenderer';

const Home: React.FC = () => {
  const {
    user,
  } = useAuth();
  const history = useHistory();
  const {
    eventInfo
  } = useEventContext();
  const state = useAsync(async () => {
    const userTickets = await (User.GetUserTicketList(user!.uid)).toPromise()
    const userTicket = userTickets!.find(u => u.EventKey! === eventInfo.EventKey);
    if (!userTicket) {
      return Promise.resolve(undefined)
    }
    const eventTicket = await Event.GetEventAttendeeDetail((userTicket.EventKey || ''), (userTicket.EventAttendeeKey || '')).toPromise()
    return eventTicket
  })
  if (state.loading) {
    return (<div>Loading</div>) // loading
  }
  if (!state.value) {
    history.push('/')
  }
  const eventAttendee = state.value!
  const qrCodeTxt = `{ "EventKey": "${eventAttendee.EventKey}", "EventAttendeeKey": "${eventAttendee.EventAttendeeKey}" }`
  return (
    <MainLayout title="ยินดีต้อนรับ">
      <IonContent>
        <div style={{textAlign: 'center', background: `calc(100% + 150px) 100% / auto 300px url(${homeGraphic}) no-repeat fixed`}}>
          <div>
            <div style={{paddingBottom: 24}}>
              <p>
                <UserDisplayName />
              </p>
              <h6>
                เข้าสู่งาน {eventInfo.EventName}
              </h6>
            </div>
            <IonImg src={eventInfo.EventCoverImage}/>
            <p className="ion-padding">
              {eventInfo!.EventDetail as any instanceof Object && (
                <RichtextRenderer value={eventInfo.EventDetail ? eventInfo!.EventDetail : ''} />
              )}
              {!(eventInfo!.EventDetail as any instanceof Object) && (
                <div
                  dangerouslySetInnerHTML={{ __html: eventInfo.EventDetail ? eventInfo!.EventDetail : '' }}
                />
              )}
            </p>
            <div style={{textAlign: 'center', paddingBottom: '20px'}}>
              <QRCode value={qrCodeTxt}/>
            </div>
            <IonButton onClick={() => history.replace(`/agenda/${eventInfo.EventKey}`)}>
              ดูกิจกรรม
            </IonButton>
            <IonButton onClick={() => history.replace('/home')}>
              กลับสู่หน้าหลัก
            </IonButton>
          </div>
        </div>
      </IonContent>
    </MainLayout>
  );
};

export default Home;
