import React from 'react'
import { IonContent, IonImg } from '@ionic/react';
import manowLogo from '../../../assets/logohorizontal.png'
// import { Link } from 'react-router-dom';
// import { Event } from '@manow/service'
// import { useObservable } from 'rxjs-hooks'
// import EventListItem from '../../Events/components/EventListItem';
// import { useAuth } from '@manow/auth';
import MainLayout from '../../../layout/MainLayout';
// import EventList from './components/EventList';
// import YourEventList from './components/YourEventList';
import FeaturedEventList from './components/FeaturedEventList';
import EndedEventList from './components/EndedEventList';
import UpcomingEventList from './components/UpcomingEventList';
// import NearByEventList from './components/NearByEventList';
// import YourLatestEvent from './components/YourLatestEvent';

// const {Swiper, Slide} = require('react-dynamic-swiper');

interface Props {
  
}

const HomePage: React.FC<Props> = () => {
  return (
    <MainLayout title={<IonImg src={manowLogo} style={{height: 40}}/>}>
      <IonContent>
        <FeaturedEventList />
        <UpcomingEventList />
        <EndedEventList />
      </IonContent>
    </MainLayout>
  )
}

export default HomePage
