export default {
  email: 'อีเมล',
  tel: 'เบอร์โทรศัพท์',
  emailAndTel: 'อีเมล หรือ เบอร์โทรศัพท์',
  password: 'รหัสผ่าน',
  'confirm password': 'ยืนยันรหัสผ่าน',
  register: 'สมัครสมาชิก',
  login: 'เข้าสู่ระบบ',
  'sign up': 'สมัครเลย',
  'sign in': 'เข้าสู่ระบบ',
  'new here?': 'ไม่มีบัญชีผู้ใช้?',
  'already have an account?': 'มีบัญชีอยู่แล้ว?',
  'forget password': 'ลืมรหัสผ่าน',
  username: 'ชื่อบัญชีของคุณ',
  firstname: 'ชื่อ',
  lastname: 'นามสกุล',
  gender: 'เพศ',
  male: 'ผู้ชาย',
  female: 'ผู้หญิง',
  passwordEdit: 'แก้ไขรหัสผ่าน',
}