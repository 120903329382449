import React from 'react'
import { useDeepCompareEffect } from 'react-use'
import { IonHeader, IonTitle, IonPage, IonToolbar, IonButtons, IonBackButton, IonImg, IonContent } from '@ionic/react'
import manowLogo from '../assets/logoManow.png'
import './MainLayout.scss'
import { useAuth } from '@manow/auth'
import { useHistory } from 'react-router';

interface Props {
  title?: string | React.ReactNode
}
const MainLayout: React.SFC<React.PropsWithChildren<Props>> = ({
  children,
  title = <IonImg src={manowLogo} />
}) => {
  const {
    user
  } = useAuth()
  const history = useHistory()
  useDeepCompareEffect(() => {
    if (!user) {
      history.replace("/home");
    } 
    if (user && !user.emailVerified) {
      history.replace("/profile/checkverifyemail");
    }
    return () => {};
  }, [user, history.location.pathname])
  return (
    <IonPage>
      <IonHeader >
        <IonToolbar class="manow-header">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle class="manow-title">
            {title}
          </IonTitle>
          {/* <IonButtons slot="end">
            <IonButton>
              <IonIcon icon={notificationsOutline} />
            </IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {children}
      </IonContent>
    </IonPage>
  )
}

export default MainLayout
