import React, { useState } from 'react';
import MainLayout from '../../layout/MainLayout';
import { IonContent, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonAvatar } from '@ionic/react';
import AvatarCircle from '../../assets/avatar-circle.png';

import 'react-dynamic-swiper/lib/styles.css'
import { useHistory } from 'react-router';
import { useEffectOnce } from 'react-use';
import { GetUserBusinessCardFromSelectEvent, ManowUserBusinessCard } from '@manow/service/dist/user';
import { useAuth } from '@manow/auth';
import _ from 'lodash'
import { useEventContext } from '../../App';

interface Props {

}

const ContactList: React.FC<Props> = () => {
    const [contactlist, setcontactlist] = useState<ManowUserBusinessCard[]>([])
    const {
        user
    } = useAuth()
    const history = useHistory();
    const { eventInfo } = useEventContext();
    useEffectOnce(() => {
        GetUserBusinessCardFromSelectEvent(_.get(user, 'uid', ''), _.get(eventInfo, 'EventKey', '')).subscribe(s => {
            setcontactlist(s);
        })
    })
    return (
        <MainLayout title="รายชื่อผู้ติดต่อ">
            <IonContent>
                {
                    contactlist.map((c, index) => {
                        return (
                            <IonCard key={index} onClick={() => history.push(`/contactdetail/${c.UserBusinessCardKey}`)}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonAvatar style={{alignSelf: 'center', width: 64, height: 64}}>
                                                <img alt="logo" src={c.UserBusinessCardCompanyLogo || AvatarCircle} />
                                            </IonAvatar>
                                            <IonCol>
                                                <h4 style={{ margin: '10px'}}><strong>{c.UserBusinessCardFirstName} {c.UserBusinessCardLastName}</strong></h4>
                                                <h4 style={{ margin: '10px'}}><strong>บริษัท</strong> {c.UserBusinessCardCompanyName}</h4>
                                                <h4 style={{ margin: '10px'}}><strong>ตำแหน่ง</strong> {c.UserBusinessCardPosition || 'ไม่ระบุ'}</h4>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        )
                    })
                }
            </IonContent>
        </MainLayout>
    )
}

export default ContactList;
